export interface PlayerType {
  name: string;
  price: number;
  priceId: string;
}

export interface Event {
  name: string;
  playerTypes: PlayerType[];
}

export const RecruitingSeminar: Event = {
  name: "Xposure Recruiting Seminar & Showcase",
  playerTypes: [
    {
      name: "Pitcher Only",
      price: 125,
      priceId: "price_1L7hdcK7R7i61EXP7po1CtU9",
    },
    {
      name: "Position Player",
      price: 175,
      priceId: "price_1L7hdcK7R7i61EXPaIzlm0Mk",
    },
    {
      name: "Two-way Player",
      price: 250,
      priceId: "price_1L7hdcK7R7i61EXP8sYICUUY",
    },
  ],
};

export const UncommittedGames2022: Event = {
  name: "Xposure Uncommitted Games",
  playerTypes: [
    {
      name: "Pitcher Only",
      price: 250,
      priceId: "price_1LGNAvK7R7i61EXPC7cSw4Ld",
    },
    {
      name: "Position Player",
      price: 250,
      priceId: "price_1LGNAvK7R7i61EXPC7cSw4Ld",
    },
    {
      name: "Two-way Player",
      price: 300,
      priceId: "price_1LGQAmK7R7i61EXPnmlTnJLb",
    },
    {
      name: "Paid for by Organization",
      price: 0,
      priceId: "paid",
    },
  ],
};

export const UncommittedGames2023: Event = {
  name: "Xposure Uncommitted Games",
  playerTypes: [
    {
      name: "Pitcher Only",
      price: 150,
      priceId: "price_1NTUtlK7R7i61EXPjMBzJFPC",
    },
    {
      name: "Position Player",
      price: 200,
      priceId: "price_1NTUtlK7R7i61EXPSlaNw4bC",
    },
    {
      name: "Two-way Player",
      price: 250,
      priceId: "price_1NTUtlK7R7i61EXPjpAjRJpa",
    },
  ],
};

export const FallLeague2022: Event = {
  name: "High School Fall League",
  playerTypes: [
    {
      name: "Pitcher Only",
      price: 250,
      priceId: "price_1LOAgMK7R7i61EXPssTCvEhr",
    },
    {
      name: "Position Player",
      price: 250,
      priceId: "price_1LOAgMK7R7i61EXPssTCvEhr",
    },
    {
      name: "Two-way Player",
      price: 250,
      priceId: "price_1LOAgMK7R7i61EXPssTCvEhr",
    },
  ],
};

export const FallLeague2023: Event = {
  name: "Fall League 2023",
  playerTypes: [
    {
      name: "All Players",
      price: 250,
      priceId: "price_1NTUvdK7R7i61EXPNUjQzGiz",
    },
  ],
};

export const FallLeagueVideo: Event = {
  name: "High School Fall League Recruiting Video",
  playerTypes: [
    {
      name: "Fall League Rostered Player",
      price: 70,
      priceId: "price_1LsYMUK7R7i61EXP8ONBGPxn",
    },
    {
      name: "Non-Rostered Player",
      price: 100,
      priceId: "price_1LsYMUK7R7i61EXP9MlTC4w6",
    },
  ],
};

export const SpringTrainingLeague: Event = {
  name: "Xposure Sports Spring Training League",
  playerTypes: [
    {
      name: "Tuesday Pitcher Only",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Tuesday Hitter/Catcher",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Tuesday Two-Way",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Thursday Pitcher Only",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Thursday Hitter/Catcher",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Thursday Two-Way",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Saturday Pitcher Only",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Saturday Hitter/Catcher",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
    {
      name: "Saturday Two-Way",
      price: 399.99,
      priceId: "price_1M8mLsK7R7i61EXPfdwruKYA",
    },
  ],
};

export const AllStarWeekend2023: Event = {
  name: "2023 Xposure Sports All-Star Weekend",
  playerTypes: [
    {
      name: "Pitcher Only",
      price: 300,
      priceId: "price_1MihlTK7R7i61EXPYdwqUmfa",
    },
    {
      name: "Position Player",
      price: 300,
      priceId: "price_1MihlTK7R7i61EXPYdwqUmfa",
    },
    {
      name: "Two-way Player",
      price: 300,
      priceId: "price_1MihlTK7R7i61EXPYdwqUmfa",
    },
  ],
};

export const SummerLeague2023: Event = {
  name: "Xposure Summer League",
  playerTypes: [
    {
      name: "Pitcher Only",
      price: 375,
      priceId: "price_1MiiCWK7R7i61EXPH1Ikc4aV",
    },
    {
      name: "Position Player",
      price: 495,
      priceId: "price_1MiiCWK7R7i61EXPGvfSsbPo",
    },
  ],
};

export const XPosureDay2023: Event = {
  name: "Xposure Day: Team XP",
  playerTypes: [
    {
      name: "Pitcher Only",
      price: 175,
      priceId: "price_1N5Yb8K7R7i61EXPprg2dhD1",
    },
    {
      name: "Position Player",
      price: 175,
      priceId: "price_1N5Yb8K7R7i61EXPprg2dhD1",
    },
    {
      name: "Two-way Player",
      price: 175,
      priceId: "price_1N5Yb8K7R7i61EXPprg2dhD1",
    },
  ],
};
