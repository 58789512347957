import * as React from "react";
import * as style from "./Pitchsmart.style";
import { classes } from "typestyle";
import { IKImage } from "imagekitio-react";

interface ComponentProps {
  className?: string;
}

const Pitchsmart: React.FC<ComponentProps> = ({ className }) => {
  return (
    <div className={classes(style.component, className)}>
      <div className={style.title}>Xposure Sports Pitching Rules</div>
      <div className={style.formatImgContainer}>
        <IKImage
          alt="Pitchsmart Logo"
          path={"/pitchsmart.png"}
          className={style.formatImg}
        />
      </div>
      <div className={style.mainInfo}>
        Below is the maximum number of pitches allowed per day in Xposure Sports
        tournaments. Players will be required to rest said number of calendar
        days when reaching pitch limit. Official pitch counts are tracked by
        Xposure Sports Field Directors unless communicated otherwise. Teams are
        encouraged to also track their pitch counts for both teams. Limits apply
        to specific tournament age divisions, not player age. (i.e. 15-year-old
        player in a 17U tournament will go by 17U requirements.)
      </div>
      <table className={style.table}>
        <thead>
          <tr>
            <th>Age</th>
            <th>Daily Pitch Max</th>
            <th>Required Rest</th>
          </tr>
          <tr>
            <th />
            <th />
            <th>0 Days</th>
            <th>1 Day</th>
            <th>2 Days</th>
            <th>3 Days</th>
            <th>4 Days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>7-8</td>
            <td>50</td>
            <td>1-20</td>
            <td>21-35</td>
            <td>36-50</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>9-10</td>
            <td>75</td>
            <td>1-20</td>
            <td>21-35</td>
            <td>36-50</td>
            <td>51-65</td>
            <td>66+</td>
          </tr>
          <tr>
            <td>11-12</td>
            <td>85</td>
            <td>1-20</td>
            <td>21-35</td>
            <td>36-50</td>
            <td>51-65</td>
            <td>66+</td>
          </tr>
          <tr>
            <td>13-14</td>
            <td>95</td>
            <td>1-20</td>
            <td>21-35</td>
            <td>36-50</td>
            <td>51-65</td>
            <td>66+</td>
          </tr>
          <tr>
            <td>15-16</td>
            <td>95</td>
            <td>1-30</td>
            <td>31-45</td>
            <td>46-60</td>
            <td>61-75</td>
            <td>76+</td>
          </tr>
          <tr>
            <td>17=18</td>
            <td>105</td>
            <td>1-30</td>
            <td>31-45</td>
            <td>46-60</td>
            <td>61-80</td>
            <td>81+</td>
          </tr>
        </tbody>
      </table>
      <ul className={style.list}>
        <li>
          Once a pitcher reaches his daily maximum limit, he must be removed
          from the game. If a pitcher reaches the maximum limit of pitches in a
          game during a batter’s plate appearance, the pitcher may continue in
          the game until such plate appearance or the inning ends (i.e. by a
          third out made by catching a runner stealing), whichever comes first.
          There is no team penalty for this, but the pitcher must adhere to
          prescribed pitch counts for the remainder of the event.
        </li>
        <li>
          The pitcher may finish the batter upon reaching the daily pitch count
          limit. I.E. A 13U pitcher begins the batter at 94 pitches; he may
          finish the current hitter then must be removed from the game.{" "}
        </li>
        <li>
          Once a player has been removed from pitching they may not return to
          the mound in the same game.
        </li>
        <li>
          If a pitcher is brought into a game sooner than the mandatory calendar
          days’ rest, he becomes an ineligible player. According to NFHS Rule
          3.1.1: An ineligible player on defense shall be replaced immediately
          upon discovery by the umpire or either team. Once discovered by teams,
          umpires, or Xposure Sports Staff, the umpire &amp; Official
          Scorekeeper will confirm eligibility with Tournament Headquarters. If
          deemed ineligible, the team will have the opportunity to remove the
          player from the game. The replacement pitcher will be allowed adequate
          time to warm up. It is not deemed forfeit.{" "}
        </li>
        <li>
          A pitcher is only allowed to pitch in one game per day unless the
          pitcher throws 20 or fewer pitches in the first game. In that case,
          the pitcher may pitch in the 2nd game of the day. Pitch totals for
          both games combined will require prescribed daily limits and required
          rest. No Pitcher is allowed to pitch 3 games in one day.
        </li>
        <li>
          No pitcher is allowed to pitch 3 days in a row regardless of pitch
          counts.
        </li>
      </ul>
    </div>
  );
};

Pitchsmart.displayName = "Pitchsmart";
export { Pitchsmart };
