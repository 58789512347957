import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  marginBottom: 60,
  fontFamily: Style.text.font.main,
  display: "flex",
  justifyContent: "center",
});

export const table = s({
  flex: 1,
});

export const logoContainer = s({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: "0 0 140px",
});
