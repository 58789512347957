import * as React from "react";
import * as style from "./SignupForm.style";
import { Button, TextInput, Dropdown } from "../../../components";
import { hooks } from "../../../utility";
import { classes } from "typestyle";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { PlayerType } from "../Events";

const SERVICE_ID = "service_c8mq82g";
const TEMPLATE_ID = "template_2pq8kj2";
const USER_ID = "user_BYHTvWHdurWUyvjL7ZRHK";
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const mobileRegex = /^[0-9]*$/;

interface ComponentProps {
  className?: string;
  onSuccess?: () => void;
  eventName: string;
  onPlayerTypeChange: (price: string) => void;
  playerTypes: PlayerType[];
  showRegistrationType?: boolean;
}

const SignupForm: React.FC<ComponentProps> = ({
  className,
  onSuccess,
  eventName,
  onPlayerTypeChange,
  showRegistrationType = true,
  playerTypes,
}) => {
  const display = hooks.useDisplayContext();

  const [name, setName] = React.useState<string | undefined>();
  const [email, setEmail] = React.useState<string | undefined>();
  const [mobile, setMobile] = React.useState<string | undefined>();
  const [height, setHeight] = React.useState<string | undefined>();
  const [weight, setWeight] = React.useState<string | undefined>();
  const [playerType, setPlayerType] = React.useState<string | undefined>();
  const [primaryPosition, setPrimaryPosition] = React.useState<
    string | undefined
  >();
  const [secondaryPosition, setSecondaryPosition] = React.useState<
    string | undefined
  >();
  const [throwsOver80, setThrowsOver80] = React.useState<string | undefined>();
  const [bats, setBats] = React.useState<string | undefined>();
  const [throws, setThrows] = React.useState<string | undefined>();
  const [tshirt, setTshirt] = React.useState<string | undefined>();
  const [school, setSchool] = React.useState<string | undefined>();
  const [gradYear, setGradYear] = React.useState<string | undefined>();
  const [twitter, setTwitter] = React.useState<string | undefined>();
  const [insta, setInsta] = React.useState<string | undefined>();
  const [gpa, setGpa] = React.useState<string | undefined>();
  const [sat, setSat] = React.useState<string | undefined>();
  const [act, setAct] = React.useState<string | undefined>();
  const [motherName, setMotherName] = React.useState<string | undefined>();
  const [motherEmail, setMotherEmail] = React.useState<string | undefined>();
  const [motherPhone, setMotherPhone] = React.useState<string | undefined>();
  const [fatherName, setFatherName] = React.useState<string | undefined>();
  const [fatherEmail, setFatherEmail] = React.useState<string | undefined>();
  const [fatherPhone, setFatherPhone] = React.useState<string | undefined>();
  const [howDidYouHear, setHowDidYouHear] = React.useState<
    string | undefined
  >();
  const [healthConcerns, setHealthConcerns] = React.useState<
    string | undefined
  >();
  const [checkboxA, setCheckboxA] = React.useState(false);
  const [checkboxB, setCheckboxB] = React.useState(false);
  const [checkboxC, setCheckboxC] = React.useState(false);

  const [nameError, setNameError] = React.useState<string | undefined>();
  const [emailError, setEmailError] = React.useState<string | undefined>();
  const [mobileError, setMobileError] = React.useState<string | undefined>();
  const [heightError, setHeightError] = React.useState<string | undefined>();
  const [weightError, setWeightError] = React.useState<string | undefined>();
  const [playerTypeError, setPlayerTypeError] = React.useState<
    string | undefined
  >();
  const [primaryError, setPrimaryError] = React.useState<string | undefined>();
  const [batsError, setBatsError] = React.useState<string | undefined>();
  const [throwsError, setThrowsError] = React.useState<string | undefined>();
  const [tshirtError, setTshirtError] = React.useState<string | undefined>();
  const [hsError, setHsError] = React.useState<string | undefined>();
  const [gradError, setGradError] = React.useState<string | undefined>();
  const [submitError, setSubmitError] = React.useState<string | undefined>();
  const [checkboxAError, setCheckboxAError] = React.useState<
    string | undefined
  >();
  const [checkboxBError, setCheckboxBError] = React.useState<
    string | undefined
  >();
  const [checkboxCError, setCheckboxCError] = React.useState<
    string | undefined
  >();

  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    if (!name) {
      setNameError("Please enter your name");
    }
    if (!email) {
      setEmailError("Please enter your email");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
    }
    if (!mobile || mobile.length < 10) {
      setMobileError("Please provide a valid mobile number");
    }
    if (!height) {
      setHeightError("Please enter your height");
    }
    if (!weight) {
      setWeightError("Please enter your weight");
    }
    if (!playerType && !!showRegistrationType) {
      setPlayerTypeError("Please select your registration type");
    }
    if (!primaryPosition) {
      setPrimaryError("Please enter your primary position");
    }
    if (!bats) {
      setBatsError("Please enter your batting preference");
    }
    if (!throws) {
      setThrowsError("Please enter your throwing arm");
    }
    if (!tshirt) {
      setTshirtError("Please enter your t-shirt size");
    }
    if (!school) {
      setHsError("Please enter your current High School");
    }
    if (!gradYear) {
      setGradError("Please enter your expected graduation year");
    }
    if (!checkboxA) {
      setCheckboxAError("Please confirm");
    }
    if (!checkboxB) {
      setCheckboxBError("Please confirm");
    }
    if (!checkboxC) {
      setCheckboxCError("Please confirm");
    }
    if (
      !!name &&
      !!email &&
      emailRegex.test(email) &&
      (!mobile || mobile.length === 10) &&
      !!height &&
      !!weight &&
      (!!playerType || !showRegistrationType) &&
      !!primaryPosition &&
      !!bats &&
      !!throws &&
      !!tshirt &&
      !!school &&
      !!gradYear &&
      !!checkboxA &&
      !!checkboxB &&
      !!checkboxC
    ) {
      setSubmitError("");
      setLoading(true);
      try {
        emailjs
          .send(
            SERVICE_ID,
            TEMPLATE_ID,
            {
              name,
              email,
              mobile,
              height,
              weight,
              twitter,
              insta,
              gpa,
              sat,
              act,
              player_type: playerType,
              primary_position: primaryPosition,
              secondary_position: secondaryPosition,
              pitching_velocity: throwsOver80,
              bats,
              throws,
              tshirt,
              school,
              grad_year: gradYear,
              mother_name: motherName,
              mother_email: motherEmail,
              mother_phone: motherPhone,
              father_name: fatherName,
              father_email: fatherEmail,
              father_phone: fatherPhone,
              message: howDidYouHear,
              event_name: eventName,
              health_concerns: healthConcerns,
              heard_how: howDidYouHear,
            },
            USER_ID,
          )
          .then(
            (response) => {
              if (onSuccess) {
                onSuccess();
              }
              setLoading(false);
            },
            (error) => {
              toast.warning(
                "Something seems to have gone wrong - please refresh the page and try again",
              );
              setLoading(false);
            },
          )
          .catch(() => {
            toast.warning(
              "We couldnt capture your information. Please try again on desktop. If this continues, please contact us",
            );
            setLoading(false);
          });
      } catch {
        setLoading(false);
        toast.warning(
          "Something seems to have gone wrong. Please refresh the page and try again. If you are on mobile, please try again on desktop.",
        );
      }
    } else {
      setSubmitError("Please fix the above errors then try again");
    }
  };

  return (
    <>
      <div className={classes(style.component, className)}>
        <div className={style.content}>
          <div className={style.subTitle}>Play Where it Matters</div>
          <div className={style.title}>Register for {eventName}</div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Name*"
              value={name}
              onChange={(val?: string) => {
                setName(val);
                setNameError("");
              }}
              error={nameError}
            />
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Email*"
              value={email}
              onChange={(val: string) => {
                setEmail(val);
                setEmailError("");
              }}
              inputType="email"
              error={emailError}
            />
            {!!showRegistrationType && (
              <Dropdown
                wrapperClassName={classes(style.dropdown, style.dropdown1)}
                options={playerTypes.map((val) => ({
                  label: `${val.name} ($${val.price})`,
                  value: val.priceId,
                }))}
                label="Registration Type*"
                onChange={(option) => {
                  setPlayerType(option.label);
                  setPlayerTypeError("");
                  onPlayerTypeChange(option.value);
                }}
                currentOption={{ label: playerType, value: playerType }}
                error={playerTypeError}
              />
            )}
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Mobile*"
              value={mobile}
              onChange={(val: string) => {
                if (mobileRegex.test(val) && val.length < 11) {
                  setMobile(val);
                  setMobileError("");
                }
              }}
              inputType="tel"
              error={mobileError}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Height*"
              value={height}
              onChange={(val?: string) => {
                setHeight(val);
                setHeightError("");
              }}
              error={heightError}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Weight*"
              value={weight}
              onChange={(val?: string) => {
                setWeight(val);
                setWeightError("");
              }}
              error={weightError}
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Primary Position*"
              value={primaryPosition}
              onChange={(val?: string) => {
                setPrimaryPosition(val);
                setPrimaryError("");
              }}
              error={primaryError}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Secondary Position"
              value={secondaryPosition}
              onChange={(val?: string) => {
                setSecondaryPosition(val);
              }}
            />
            <Dropdown
              wrapperClassName={classes(style.dropdown, style.dropdown2)}
              options={["Yes", "No"].map((val) => ({
                label: val,
                value: val,
              }))}
              label="Pitch Velocity 80+"
              onChange={(option) => {
                setThrowsOver80(option.label);
              }}
              currentOption={{ label: throwsOver80, value: throwsOver80 }}
            />
          </div>
          <div className={style.inputSection}>
            <Dropdown
              wrapperClassName={classes(style.dropdown, style.dropdown3)}
              options={["Right", "Left", "Switch"].map((val) => ({
                label: val,
                value: val,
              }))}
              label="Bats*"
              onChange={(option) => {
                setBats(option.label);
                setBatsError("");
              }}
              currentOption={{ label: bats, value: bats }}
              error={batsError}
            />
            <Dropdown
              wrapperClassName={classes(style.dropdown, style.dropdown3)}
              options={["XS", "S", "M", "L", "XL", "XXL", "3XL"].map((val) => ({
                label: val,
                value: val,
              }))}
              label="T-Shirt Size*"
              onChange={(option) => {
                setTshirt(option.label);
                setTshirtError("");
              }}
              currentOption={{ label: tshirt, value: tshirt }}
              error={tshirtError}
            />
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="High School*"
              value={school}
              onChange={(val?: string) => {
                setSchool(val);
                setHsError("");
              }}
              error={hsError}
            />
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Graduation Year*"
              value={gradYear}
              onChange={(val?: string) => {
                setGradYear(val);
                setGradError("");
              }}
              error={gradError}
            />
            <Dropdown
              wrapperClassName={classes(style.dropdown, style.dropdown4)}
              options={["Right", "Left"].map((val) => ({
                label: val,
                value: val,
              }))}
              label="Throws*"
              onChange={(option) => {
                setThrows(option.label);
                setThrowsError("");
              }}
              currentOption={{ label: throws, value: throws }}
              error={throwsError}
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Twitter Handle"
              value={twitter}
              onChange={(val?: string) => {
                setTwitter(val);
              }}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Instagram Handle"
              value={insta}
              onChange={(val?: string) => {
                setInsta(val);
              }}
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="GPA (optional)"
              value={gpa}
              onChange={(val?: string) => {
                setGpa(val);
              }}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="SAT (optional)"
              value={sat}
              onChange={(val?: string) => {
                setSat(val);
              }}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="ACT (optional)"
              value={act}
              onChange={(val?: string) => {
                setAct(val);
              }}
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Mother/Guardian Name"
              value={motherName}
              onChange={(val?: string) => {
                setMotherName(val);
              }}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Mother/Guardian Email"
              value={motherEmail}
              onChange={(val: string) => {
                setMotherEmail(val);
              }}
              inputType="email"
            />
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Mother/Guardian Mobile"
              value={motherPhone}
              onChange={(val: string) => {
                if (mobileRegex.test(val) && val.length < 11) {
                  setMotherPhone(val);
                }
              }}
              inputType="tel"
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Father/Guardian Name"
              value={fatherName}
              onChange={(val?: string) => {
                setFatherName(val);
              }}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="Father/Guardian Email"
              value={fatherEmail}
              onChange={(val: string) => {
                setFatherEmail(val);
              }}
              inputType="email"
            />
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Father/Guardian Mobile"
              value={fatherPhone}
              onChange={(val: string) => {
                if (mobileRegex.test(val) && val.length < 11) {
                  setFatherPhone(val);
                }
              }}
              inputType="tel"
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.messageInput}
              label="How did you hear about us?"
              value={howDidYouHear}
              onChange={(val: string) => {
                setHowDidYouHear(val);
              }}
              isTextArea={true}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.messageInput}
              label="Health concerns or medical problems"
              value={healthConcerns}
              onChange={(val: string) => {
                setHealthConcerns(val);
              }}
              isTextArea={true}
            />
          </div>
          <div className={style.checkbox}>
            <input
              type="checkbox"
              checked={checkboxA}
              onChange={() => {
                setCheckboxA(!checkboxA);
                setCheckboxAError("");
              }}
            />
            <label>
              *I hereby authorize Xposure Sports to act for me in judgment in
              any emergency requiring medical attention. I hereby waive, release
              and indemnify Xposure Sports of all legal responsibilities in the
              event of injury to my child. I know of no mental or physical
              problems, which might affect my child's ability to safely
              participate in this camp. I will be responsible for any medical
              charges in connection with his/her attendance of the camp, before,
              during or while leaving any program. *Please list any health or
              medical problems of the registrant.
            </label>
            <div className={style.checkBoxError}>{checkboxAError}</div>
          </div>
          <div className={style.checkbox}>
            <input
              type="checkbox"
              checked={checkboxB}
              onChange={() => {
                setCheckboxB(!checkboxB);
                setCheckboxBError("");
              }}
            />
            <label>
              *As a condition of participation in a Xposure Sports and/or a
              licensee and/or an affiliate sponsored event, I hereby give to
              Xposure Sports, LLC, its affiliates, licensees and sponsors, my
              full and complete permission and consent to use and disclose my
              name and registered contact information, including home address
              and telephone number, and my picture, image, video, likeness,
              actions, voice, or other personally identifiable information
              (collectively your “Name and Image”), in whole or in part,
              individually or in conjunction with other images, captured by any
              means as part of my registration with and/or participation in any
              Xposure Sports and/or a licensee and/or an affiliate sponsored
              event.
            </label>
            <div className={style.checkBoxError}>{checkboxBError}</div>
          </div>
          <div className={style.checkbox}>
            <input
              type="checkbox"
              checked={checkboxC}
              onChange={() => {
                setCheckboxC(!checkboxC);
                setCheckboxCError("");
              }}
            />
            <label>
              *I waive all rights of privacy or compensation, which I may have
              in connection with my Name and Image as so captured in any media
              whatsoever, to be used by Xposure Sports and/or its licensees,
              affiliates and/or sponsors for promotional purposes. I grant
              Xposure Sports LLC and its affiliates, partners, sponsors,
              officials, employees, representatives, agents, licensees, and
              assigns the irrevocable and unrestricted right to use my Name and
              Image associated with any Xposure Sports and/or affiliate
              sponsored events, media and in all manners, including the
              composite or altered representations. I consent to such uses and
              hereby waive all rights to compensation and any right to inspect
              or approve the finished product or image, regardless of format.
            </label>
            <div className={style.checkBoxError}>{checkboxCError}</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              className={style.button}
              rounded={false}
              disabled={
                !!emailError ||
                !!mobileError ||
                !!nameError ||
                !!heightError ||
                !!weightError ||
                !!throwsError ||
                !!tshirtError ||
                !!batsError ||
                !!hsError ||
                !!gradError ||
                !!primaryError ||
                !!checkboxAError ||
                !!checkboxBError ||
                !!checkboxCError ||
                !!playerTypeError
              }
              loading={loading}
              onClick={onSubmit}
            >
              Sign Up
            </Button>
            <div className={style.error}>{submitError}</div>
          </div>
        </div>
      </div>
    </>
  );
};

SignupForm.displayName = "SignupForm";
export { SignupForm };
