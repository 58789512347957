import { media, style as s, style } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    boxShadow: "0px 2px 2px 0px #00000040",
    marginBottom: 25,
    fontSize: 0,
    transition: ".2s ease-in",
    cursor: "pointer",
    $nest: {
      "&:hover": {
        boxShadow: "0px 0px 4px 4px #00000040",
      },
      a: {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 calc(33.3% - 15px)",
      marginBottom: 0,
    },
  ),
);

export const container = s({
  position: "relative",
});

export const overlay = s({
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 2,
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.54) 82.81%)",
});

export const postImage = s({
  width: "100%",
  aspectRatio: "4 / 3",
  zIndex: 1,
  objectFit: "cover",
});

export const postContent = s({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 3,
  padding: "15px 10px",
  color: Style.colors.white,
  fontFamily: Style.text.font.third,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const postTitle = s(
  {
    fontSize: Style.text.fontSize.small,
    fontWeight: 500,
    marginBottom: 10,
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      fontSize: Style.text.fontSize.medLarge,
    },
  ),
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 18,
    },
  ),
);
export const author = s(
  {
    fontSize: 13,
    color: "#E2E2E2",
    fontWeight: 700,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 11,
    },
  ),
);
