import * as React from "react";
import * as style from "./Tournaments.style";
import { Banner, Icon, RankingsTable } from "../../components";
import { connect, useDispatch } from "react-redux";
import { ActionTypes } from "../../store/actions";
import { RootState } from "../../store/reducers";
import * as Models from "../../models";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";
import { Rules } from "./Rules";
import { Link, useParams, Navigate } from "react-router-dom";
import {
  CharmCityClassic1718,
  CharmCityClassic1314,
  CharmCityClassic1516,
  BattleRoyale,
} from "./TournamentDetails";

const tournamentMap: Record<string, JSX.Element> = {
  "charm-city-classic-u17-u18": <CharmCityClassic1718 />,
  "charm-city-classic-u13-u14": <CharmCityClassic1314 />,
  "charm-city-classic-u15-u16": <CharmCityClassic1516 />,
  "battle-royale": <BattleRoyale />,
};

interface StateProps {
  players: { [year: string]: Models.Player[] };
  schools: { [division: string]: Models.School[] };
  inProgress: boolean;
  posts: Models.Post[];
}

const TournamentsInternal: React.FC<StateProps> = ({
  players,
  schools,
  inProgress,
  posts,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  React.useEffect(() => {
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
    dispatch({ type: ActionTypes.GET_RANKINGS });
  }, []);
  const display = hooks.useDisplayContext();
  const { slug } = params;
  const [tournament, setTournament] = React.useState(tournamentMap[slug]);
  React.useEffect(() => {
    setTournament(tournamentMap[slug]);
  }, [slug]);
  return !tournament ? (
    <Navigate replace to="/" />
  ) : (
    <div className={style.component}>
      <IKImage
        alt="Maryland High School Baseball Rankings"
        path="/maryland-high-school-baseball-rankings-2.png"
        className={style.bgImage}
      />
      <div className={style.content}>
        <div className={style.mainSection}>
          {tournament}
          <div className={style.shareContainer}>
            <IKImage
              alt="Xposure Sports Logo"
              path="/full-logo.png"
              width={display.isMobile ? 150 : 226}
            />
          </div>
          <Banner className={style.header} title="Recent Articles" />
          <div className={style.articles}>
            {posts.slice(0, 20).map((post) => (
              <Link
                to={`/posts/${post.slug}`}
                className={style.link}
                key={post.slug}
              >
                <div className={style.story}>
                  {post.title.rendered}
                  <span> Kyle Campbell</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {!display.isMobile && (
          <div className={style.sidebar}>
            <Banner title="Latest Tweets" />
            <a
              className="twitter-timeline"
              data-height="650"
              data-chrome="noscrollbar noheader nofooter"
              href="https://twitter.com/xposure_sports?ref_src=twsrc%5Etfw"
            />
            {!!inProgress ? (
              <Icon.Spinner size={30} />
            ) : (
              <>
                <RankingsTable
                  className={style.sidebarHeader}
                  schools={schools}
                  type="Team"
                  limit={10}
                  isSidebar={true}
                />
                <RankingsTable
                  className={style.sidebarHeader}
                  players={players}
                  type="Player"
                  limit={10}
                  isSidebar={true}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

TournamentsInternal.displayName = "Tournaments";
export const Tournaments = connect((state: RootState) => {
  return {
    players: state.rankings.players,
    schools: state.rankings.schools,
    inProgress: state.rankings.inProgress,
    posts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
  };
}, {})(TournamentsInternal);
