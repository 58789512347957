import { media, style as s, style } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  fontFamily: Style.text.font.main,
  boxSizing: "border-box",
  position: "relative",
  background: "#FEFEFE",
});

export const mobileTitle = s({
  fontSize: 22,
  textAlign: "center",
  lineHeight: "28px",
  color: Style.colors.second,
  fontWeight: 800,
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, .55) 0%, rgba(255, 255, 255, 0.85) 82.81%)",
  clipPath: "polygon(15px 0%, calc(100% - 15px) 0%, 100% 100%, 0% 100%)",
  marginBottom: 10,
  fontStyle: "italic",
  padding: `10px 25px`,
  textTransform: "uppercase",
});

export const subTitle = s({
  fontSize: 18,
  lineHeight: "20px",
  textTransform: "none",
  fontWeight: 800,
  color: `${Style.colors.second}90`,
});

export const bgImage = s({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  zIndex: 1,
});

export const content = s(
  {
    position: "relative",
    zIndex: 2,
    padding: "90px 15px 20px",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 20,
    },
  ),
);

export const table = s({
  width: "100%",
  marginBottom: 20,
});

export const flexSection = s({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const sidebar = s({
  flex: "0 0 calc(25% - 25px)",
  background: "#FFFFFF",
});

export const post = s({
  marginBottom: 10,
});

export const sidebarHeader = s({
  fontSize: 22,
  fontWeight: 700,
  padding: 10,
  color: "#3A3A3A",
  borderTop: "6px solid #091051",
  borderBottom: `1px solid ${Style.colors.darkTranslucent500}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#FFFFFF",
});

export const mainSection = s({
  flex: "0 0 calc(75% - 25px)",
});
