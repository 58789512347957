import * as React from "react";
import * as style from "./Rankings.style";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import { ActionTypes } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { IKImage } from "imagekitio-react";
import * as Models from "../../models";
import { Banner, Icon, RankingsTable } from "../../components";
import { hooks } from "../../utility";

interface StateProps {
  players: { [year: string]: Models.Player[] };
  schools: { [division: string]: Models.School[] };
  inProgress: boolean;
  posts: Models.Post[];
}

const RankingsInternal: React.FC<StateProps> = ({
  players,
  schools,
  inProgress,
  posts,
}) => {
  const dispatch = useDispatch();
  const display = hooks.useDisplayContext();
  const [selectedHeaderTab, setSelectedHeaderTab] = React.useState<
    "Player" | "Team"
  >("Player");
  React.useEffect(() => {
    dispatch({ type: ActionTypes.GET_RANKINGS });
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
  }, []);
  return (
    <>
      <div className={style.component}>
        <IKImage
          alt="Maryland High School Baseball Rankings"
          path="/maryland-high-school-baseball-rankings-2.png"
          className={style.bgImage}
        />
        <div className={style.content}>
          <div className={style.flexSection}>
            <div className={style.mainSection}>
              <div className={style.header}>
                <div
                  className={
                    selectedHeaderTab === "Player"
                      ? style.selectedHeaderTab
                      : style.headerTab
                  }
                  onClick={() => setSelectedHeaderTab("Player")}
                >
                  Player Rankings
                </div>
                <div
                  className={
                    selectedHeaderTab === "Team"
                      ? style.selectedHeaderTab
                      : style.headerTab
                  }
                  onClick={() => setSelectedHeaderTab("Team")}
                >
                  Team Rankings
                </div>
              </div>
              {inProgress ? (
                <Icon.Spinner size={40} />
              ) : (
                <RankingsTable
                  schools={schools}
                  players={players}
                  type={selectedHeaderTab}
                  className={style.table}
                  hideSeeMore={true}
                />
              )}
              <Banner className={style.banner} title="Recent Articles" />
              <div className={style.articles}>
                {posts.slice(0, 20).map((post) => (
                  <Link
                    to={`/posts/${post.slug}`}
                    className={style.link}
                    key={post.slug}
                  >
                    <div className={style.story}>
                      {post.title.rendered}
                      <span> Kyle Campbell</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            {!display.isMobile && (
              <div className={style.sidebar}>
                <Banner title="Recent Articles" />
                {!!posts &&
                  posts.slice(0, 10).map((post) => (
                    <Link
                      to={`/posts/${post.slug}`}
                      key={post.slug}
                      className={style.sidebarLink}
                    >
                      <div className={style.sidebarItem}>
                        {post.title.rendered}
                      </div>
                    </Link>
                  ))}
                <Banner title="Latest Tweets" className={style.sidebarHeader} />
                <a
                  className="twitter-timeline"
                  data-height="650"
                  data-chrome="noscrollbar noheader nofooter"
                  href="https://twitter.com/xposure_sports?ref_src=twsrc%5Etfw"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

RankingsInternal.displayName = "Rankings";
export const Rankings = connect((state: RootState) => {
  return {
    players: state.rankings.players,
    schools: state.rankings.schools,
    inProgress: state.rankings.inProgress,
    posts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
  };
}, {})(RankingsInternal);
