import { media, style as s } from "typestyle";
import * as Style from "../../styles";
import { postContent } from "../../components/PostTile/PostTile.style";

export const component = s(
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    fontFamily: Style.text.font.main,
    boxSizing: "border-box",
    position: "relative",
    minHeight: "90vh",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 10,
    },
  ),
);

export const bgImage = s({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  zIndex: 1,
});

export const container = s({
  position: "relative",
  zIndex: 2,
});

export const content = s({
  display: "flex",
  justifyContent: "space-between",
});

export const postContainer = s({
  flex: "0 0 calc(75% - 25px)",
  background: Style.colors.translucent880,
  padding: 20,
});

export const sidebar = s({
  flex: "0 0 calc(25% - 25px)",
  background: "#FFFFFF",
  paddingTop: 20,
});

export const posts = s({
  marginBottom: 35,
  background: "#FFFFFF88",
  padding: "20px 0px 0px 15px",
});

export const flexSection = s({
  display: "flex",
  justifyContent: "space-between",
});

export const featuredPost = s({
  flex: "0 0 calc(50% - 10px)",
  transition: ".2s ease-in",
  cursor: "pointer",
  fontSize: 0,
  $nest: {
    a: {
      color: "inherit",
      textDecoration: "none",
    },
  },
});

export const featuredImage = s({
  width: "100%",
});

export const featuredContent = s({
  padding: `10px 0px`,
});

export const featuredTitle = s({
  fontSize: 37,
  lineHeight: "43px",
  fontWeight: 700,
  marginBottom: 10,
  color: "#343434",
});

export const author = s({
  fontSize: 10,
  color: "#898989",
  fontFamily: Style.text.font.third,
  marginBottom: 10,
});

export const secondarySection = s({
  flex: "0 0 calc(50% - 16px)",
});

export const sidebarHeader = s({
  fontSize: 22,
  fontWeight: 700,
  padding: 10,
  color: "#3A3A3A",
  borderTop: "6px solid #091051",
  borderBottom: `1px solid ${Style.colors.darkTranslucent500}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const sidebarLink = s({
  textDecoration: "none",
});

export const sidebarItem = s({
  fontSize: 16,
  padding: 10,
  fontWeight: 500,
  color: Style.colors.black2,
  borderBottom: `1px solid ${Style.colors.darkTranslucent500}`,
  textDecoration: "none",
  $nest: {
    "&:hover": {
      color: "#3D3D3D",
    },
  },
});

export const topPost = s({
  flex: "0 0 calc(50% - 16px)",
  $nest: {
    [`.${postContent}`]: {
      height: "auto",
      minHeight: 60,
    },
  },
  marginBottom: 0,
});

export const morePosts = s({
  display: "flex",
  flexWrap: "wrap",
});

export const post = s({
  flex: "0 0 calc(33.3% - 12px)",
  margin: `0px 18px 25px 0px`,
  $nest: {
    "&:nth-child(3n)": {
      marginRight: 0,
    },
  },
});

export const mobileContent = s({
  position: "relative",
  zIndex: 2,
  padding: "90px 15px 20px",
});

export const banner = s(
  {
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 25,
    },
  ),
);
export const articles = s({
  background:
    "linear-gradient(180deg, #F2F2F2 0%, rgba(250, 250, 250, 0.85) 0.01%, rgba(255, 255, 255, 0.74) 100%)",
  padding: 20,
  boxShadow: "0px 2px 2px 0px #00000040",
});

export const link = s({
  textDecoration: "none",
});

export const story = s({
  color: "#292929",
  marginBottom: 15,
  fontSize: "18px",
  $nest: {
    span: {
      color: "#818181",
      paddingLeft: 5,
    },
    "&:hover": {
      cursor: "pointer",
      color: Style.colors.primary,
    },
  },
});

export const sidebarBanner = s({
  marginTop: 20,
});
export const dropdown = s({
  marginBottom: 15,
  position: "relative",
  zIndex: 4,
  backgroundColor: Style.colors.second,
});

export const dropdownInput = s({
  backgroundColor: Style.colors.second,
});

export const mobilePlaceholder = s({
  marginBottom: 40,
  fontSize: 22,
  lineHeight: "26px",
  zIndex: 1,
  padding: 10,
  backgroundColor: Style.colors.translucent880,
});
