import * as React from "react";
import * as style from "./RankingsTable.style";
import * as Models from "../../models";
import { classes } from "typestyle";
import { Link } from "react-router-dom";
import { Banner } from "../Banner";

interface ComponentProps {
  className?: string;
  type: "Player" | "Team";
  players?: { [year: string]: Models.Player[] };
  schools?: { [division: string]: Models.School[] };
  limit?: number;
  hideSeeMore?: boolean;
  isSidebar?: boolean;
}

const years = ["2023", "2024", "2025", "2026"];
const divisions = ["MIAA", "4A", "3A", "2A", "1A"];

const RankingsTable: React.FC<ComponentProps> = ({
  type,
  players,
  schools,
  className,
  limit = 1000,
  hideSeeMore = false,
  isSidebar = false,
}) => {
  const [selectedSubHeaderTab, setSelectedSubHeaderTab] =
    React.useState<string>(type === "Player" ? "2023" : "MIAA");
  React.useEffect(() => {
    setSelectedSubHeaderTab(type === "Player" ? "2023" : "MIAA");
  }, [type]);
  return (
    <div
      className={classes(
        className,
        style.component,
        isSidebar && style.sidebar,
      )}
    >
      <Banner title={`${type}${type === "Team" ? " Power" : ""} Rankings`} />
      <div className={style.subHeader}>
        {type === "Player" ? (
          <>
            {years.map((year) => (
              <div
                className={classes(
                  style.subHeaderTab,
                  selectedSubHeaderTab === year
                    ? style.selectedSubHeaderTab
                    : "",
                )}
                key={year}
                onClick={() => setSelectedSubHeaderTab(year)}
              >
                {year}
              </div>
            ))}
          </>
        ) : (
          <>
            {divisions.map((division) => (
              <div
                className={classes(
                  style.subHeaderTab,
                  selectedSubHeaderTab === division
                    ? style.selectedSubHeaderTab
                    : "",
                )}
                key={division}
                onClick={() => setSelectedSubHeaderTab(division)}
              >
                {division.replace("MIAA", "Private")}
              </div>
            ))}
          </>
        )}
      </div>
      <table className={style.table}>
        <thead>
          {type === "Player" ? (
            <tr>
              <th style={{ width: "10%" }}>Rank</th>
              <th style={{ width: "40%" }}>Name</th>
              <th style={{ width: "10%" }}>Position</th>
              <th style={{ width: "40%" }}>School</th>
            </tr>
          ) : (
            <tr>
              <th style={{ width: "15%" }}>Rank</th>
              <th style={{ width: "60%" }}>School</th>
              <th style={{ width: "25%" }}>Record</th>
            </tr>
          )}
        </thead>
        <tbody>
          {type === "Player" ? (
            <>
              {!!players &&
                !!players[selectedSubHeaderTab] &&
                players[selectedSubHeaderTab]
                  .slice(0, limit)
                  .map((player, i) => (
                    <tr key={`${player.name}${i}`}>
                      <td>{i + 1}.</td>
                      <td>{player.name}</td>
                      <td>{player.position}</td>
                      <td>{player.school}</td>
                    </tr>
                  ))}
            </>
          ) : (
            <>
              {!!schools &&
                !!schools[selectedSubHeaderTab] &&
                schools[selectedSubHeaderTab]
                  .slice(0, limit)
                  .map((school, i) => (
                    <tr key={`${school.name}${i}`}>
                      <td>{i + 1}.</td>
                      <td>{school.name}</td>
                      <td>{school.record}</td>
                    </tr>
                  ))}
            </>
          )}
        </tbody>
      </table>
      {!hideSeeMore &&
        ((type === "Player" &&
          !!players &&
          !!players[selectedSubHeaderTab] &&
          players[selectedSubHeaderTab].length > limit) ||
          (type === "Team" &&
            !!schools &&
            !!schools[selectedSubHeaderTab] &&
            schools[selectedSubHeaderTab].length > limit)) && (
          <Link to="/rankings" className={style.seeMore}>
            <div>See More</div>
          </Link>
        )}
    </div>
  );
};

RankingsTable.displayName = "Tournaments";
export { RankingsTable };
