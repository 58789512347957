import * as React from "react";
import * as style from "./TournamentDetails";
import { Link } from "react-router-dom";
import { Rules } from "../Rules";

interface ComponentProps {
  className?: string;
}

const CharmCityClassic1516: React.FC<ComponentProps> = ({ className }) => {
  return (
    <>
      <div className={style.event}>
        <div>
          <div className={style.eventHeader}>
            <div className={style.eventName}>15U/16U Charm City Classic</div>
            <div className={style.location}>October 1st - 2nd</div>
            <div className={style.cost}>
              The inaugural 15U/16U Charm City Classic will be held at various
              quality high school and college fields throughout the greater
              Baltimore area.
            </div>
            <div className={style.divisions}>Divisions</div>
            <div className={style.divisions}>
              <b>15U:</b> 2026 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2007
            </div>
            <div className={style.divisions}>
              <b>16U:</b> 2025 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2006
            </div>
            <div>
              <Link to={`/tournaments/register`} className={style.link}>
                <div className={style.register}>Request Invite</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.mainInfo}>
        The 15U/16U Charm City Classic is a 16 team tournament with two 8 team
        divisions, 15U and 16U.This tournament is expected to sell out quickly!
      </div>
      <div className={style.subInfo}>
        *Xposure Sports reserves the right to change the format based on the
        number of teams registered for each age group; however, 13U and 14U will
        not be combined in this tournament for any reason.
      </div>
      <Rules formatImgUrl="/bracket-2.png" />
    </>
  );
};

CharmCityClassic1516.displayName = "CharmCityClassic1516";
export { CharmCityClassic1516 };
