import * as React from "react";
import * as style from "./TournamentDetails";
import { Link } from "react-router-dom";
import { Rules } from "../Rules";

interface ComponentProps {
  className?: string;
}

const CharmCityClassic1314: React.FC<ComponentProps> = ({ className }) => {
  return (
    <>
      <div className={style.event}>
        <div>
          <div className={style.eventHeader}>
            <div className={style.eventName}>13U/14U Charm City Classic</div>
            <div className={style.location}>September 23rd-25th</div>
            <div className={style.cost}>
              The inaugural 13U/14U Charm City Classic will be held at various
              quality high school and college fields throughout the greater
              Baltimore area.
            </div>
            <div className={style.divisions}>Divisions</div>
            <div className={style.divisions}>
              <b>13U:</b> Players born on/after May 1, 2008 are eligible.{" "}
              <b>ONLY TWO</b> school grade exemptions per team will be allowed
              for players who are in the 7th grade who turn 14 years old before
              May 1st, 2022. However, those players must be born on/after
              January 1, 2008 to be eligible.
            </div>
            <div className={style.divisions}>
              <b>14U:</b> 2027 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2008
            </div>
            <div>
              <Link to={`/tournaments/register`} className={style.link}>
                <div className={style.register}>Request Invite</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.mainInfo}>
        The 13U/14U Charm City Classic is a 16 team tournament with two 8 team
        divisions, 13U and 14U.This tournament is expected to sell out quickly!
      </div>
      <div className={style.subInfo}>
        *Xposure Sports reserves the right to change the format based on the
        number of teams registered for each age group; however, 13U and 14U will
        not be combined in this tournament for any reason.
      </div>
      <Rules formatImgUrl="/bracket-2.png" metalBat={true} />
    </>
  );
};

CharmCityClassic1314.displayName = "CharmCityClassic1314";
export { CharmCityClassic1314 };
