import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    position: "relative",
    marginBottom: 35,
    boxSizing: "border-box",
    fontFamily: Style.text.font.main,
    padding: "90px 10px 20px",
  },
  media({ minWidth: Style.screensize.mdMin }, { padding: 20 }),
);

export const bgImage = s({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,
});

export const content = s({
  backgroundColor: Style.colors.translucent880,
  display: "flex",
  justifyContent: "space-between",
  zIndex: 2,
  position: "relative",
});

export const postContainer = s(
  {
    flex: 1,
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 calc(67% - 25px)",
    },
  ),
);

export const sidebar = s({
  flex: "0 0 calc(33% - 25px)",
  background: "#FFFFFF",
});

export const sidebarHeader = s({
  fontSize: 22,
  fontWeight: 700,
  padding: 10,
  color: "#3A3A3A",
  borderTop: "6px solid #091051",
  borderBottom: `1px solid ${Style.colors.darkTranslucent500}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const sidebarLink = s({
  textDecoration: "none",
});

export const sidebarItem = s({
  fontSize: 16,
  padding: 10,
  fontWeight: 500,
  color: Style.colors.black2,
  borderBottom: `1px solid ${Style.colors.darkTranslucent500}`,
  textDecoration: "none",
  $nest: {
    "&:hover": {
      color: "#3D3D3D",
    },
  },
});

export const articles = s({
  background:
    "linear-gradient(180deg, #F2F2F2 0%, rgba(250, 250, 250, 0.85) 0.01%, rgba(255, 255, 255, 0.74) 100%)",
  padding: 20,
  boxShadow: "0px 2px 2px 0px #00000040",
});

export const link = s({
  textDecoration: "none",
});

export const story = s({
  color: "#292929",
  marginBottom: 15,
  fontSize: "18px",
  $nest: {
    span: {
      color: "#818181",
      paddingLeft: 5,
    },
    "&:hover": {
      cursor: "pointer",
      color: Style.colors.primary,
    },
  },
});

export const sidebarBanner = s({
  marginTop: 20,
});
