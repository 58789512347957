import * as React from "react";
import * as style from "./Rules.style";
import { classes } from "typestyle";
import { Link } from "react-router-dom";
import { IKImage } from "imagekitio-react";

interface ComponentProps {
  className?: string;
  metalBat?: boolean;
  formatImgUrl?: string;
  isPoolPlay?: boolean;
}

const Rules: React.FC<ComponentProps> = ({
  className,
  metalBat,
  formatImgUrl,
  isPoolPlay,
}) => {
  return (
    <div className={classes(style.component, className)}>
      <div className={style.batRules}>Additional Rules:</div>
      <div className={style.details}>
        <b>
          {!!metalBat ? "Metal/Composite or wood bat only" : "Wood Bat Only"}
        </b>
      </div>
      <div className={style.details}>
        <b>ENTRY FEE:</b> $1,095
      </div>
      <div className={style.details}>
        No refunds will be issued at anytime after payment. Xposure Sports will
        hold a credit to a future event if notice of cancellation is given at
        least 90 days prior.
      </div>
      <div className={style.details}>
        <b>GATE FEE:</b> $250 (INDIVIDUAL GATE FEE WILL NOT BE COLLECTED AT THIS
        EVENT)
      </div>
      <div className={style.details}>
        <IKImage
          alt="Tournament Format"
          path={"/iscore.png"}
          className={style.iscoreImg}
        />
        This tournament will be scored on iScore
      </div>
      <div className={style.details}>
        <b>Format:</b> Bracket Style with winner’s advancing towards the
        Championship.
      </div>
      {!!formatImgUrl && (
        <div className={style.formatImgContainer}>
          <IKImage
            alt="Tournament Format"
            path={formatImgUrl}
            className={style.formatImg}
          />
        </div>
      )}
      {!isPoolPlay && (
        <div className={style.details}>
          <b>Draw Process:</b> The draw will be posted on the Monday evening
          prior to the weekend’s tournament. No two teams from the same
          organization can be drawn on the same section of the bracket. Teams
          will be placed into a hopper and drawn to fill out the bracket in
          order starting at the top.
        </div>
      )}
      <ol className={style.rulesList}>
        <li>
          Home/Away:{" "}
          {isPoolPlay
            ? "Pool Play Pre-Determined / Higher Seeded Team is Home in the Championship"
            : "Home and Away will be determined by a coin flip Time"}
        </li>
        <li>
          Limits: All games except for the Championship will have a strict{" "}
          {isPoolPlay ? "two-hour and fifteen minute" : "2 hour"} time limit.
          This rule is subject to change. No inning can start after{" "}
          {isPoolPlay ? "two hours and fifteen minutes" : "2 hours"}. Time will
          be kept by the Xposure Sports Field Director and first pitch will
          constitute the start of the game clock. Any time limit discrepancies
          need to be protested before teams leave the field. We recommend that
          the protesting team request the Xposure Sports Field Director and/or
          Umpires to contact their supervisor(s) immediately to verify time and
          make final ruling on if the game should continue. (Please see below on
          how to properly protest.)
        </li>
        <li>
          Tie-Breaker Rules: If after 7 innings or{" "}
          {isPoolPlay ? "two hours and fifteen minutes" : "two hours"} the two
          teams are still tied the following tie breaker rules will go into
          effect except for the Championship Game. The last three hitters from
          the previous inning will load the bases with one out. The base runners
          will be placed as follows: Last hitter from previous inning @ 1st Base
          Hitter before him in the lineup @ 2nd Base Hitter before that in the
          lineup @ 3rd Base Normal substitution rules apply to these runners.
          The hitting team will start the inning with one out. Play continues
          until one team is ahead at the end of an inning. Championship games
          will be played with regular extra innings through the 9th inning.
          Starting in the 10th inning the last three hitters from the previous
          inning will load the bases with one out.{" "}
        </li>
        <li>
          Forfeits: All games must be started with at least 9 players. A player
          must be at least 13 years old to compete in a 16U-18U event. A game
          can be finished with 8 players. If there are no eligible subs the
          player leaving the original lineup will be recorded as an out until he
          is able to return. The tournament committee will rule on all forfeits.
          The committee’s decision will be final!{" "}
        </li>
        <li>
          Taking Infield: No infield will be allowed during the tournaments.{" "}
        </li>
        <li>
          Mercy Rules: 10 runs after 5 innings or 4 ½ if the home team is ahead.
          Trips to the mound: The members of the coaching staff (including the
          manager) can make one mound visit per pitcher per inning without
          needing to remove the pitcher from the game. If the same pitcher is
          visited twice in one inning, the pitcher must be removed from the
          contest.{" "}
        </li>
        <li>
          Hitting lineup: Can consist of 9, 10, or 11 players. The lineup must
          stay with the same number of batters for the entire game. Once the
          first pitch has been thrown the lineup is locked, and the number of
          batters may not be changed. The 10th and 11th hitter if used will be
          designated as an EH and XH on the lineup card. The EH and XH may bat
          at any spot in the order. The EH and XH is the same as any position on
          the field as far as substitutions are concerned. The starting pitcher
          is allowed to DH for himself and bat in any slot in the order but must
          be listed a P/DH on the lineup card.{" "}
        </li>
        <li>
          In case of rain: All rain out games may not be made up depending on
          time constraints. In case of scheduling changes, it is the team's
          responsibility, not the tournament committee, to make sure they are
          aware of the new schedule or any scheduling changes. This information
          will be posted on the tournament website and on social media when it
          becomes available.{" "}
        </li>
        <li>
          Note: Tournament format is subject to change at any time if deemed
          necessary and will be determined based on the fairest outcome for all
          teams. All coaches should make sure the tournament committee has
          current contact info. If games are canceled, Championship side games
          will take priority).{" "}
        </li>
        <li>
          Stop in Play: If a game is stopped, the game must start within 1 hour
          of the stop in play. If games are not resumed, they will become
          suspended or called official. Games called by umpires that cannot be
          resumed within 1 hour will be considered an official game, if 5
          innings have been completed, or 4 1/2 innings if the home team is
          ahead. After 5 complete innings, a tie score will result in an
          automatic suspended game. A suspended game will resume where the game
          was halted.{" "}
        </li>
        <li>
          Protests: Judgment calls and pitching limitations are not eligible for
          protest. Protests will be heard and ruled on by the tournament
          committee. The team protesting must make cash payment of $100 at the
          time of the protest. If the protest is ruled in the protesting team’s
          favor the $100 will be refunded. The ruling made by the committee will
          be final. Protests must be filed and ruled on before the next pitch if
          during game, or before umpires leave field if after a game.{" "}
        </li>
        <li>
          Roster and Roster Changes: All players must be listed on the official
          roster prior to the team’s first game. Playing a non rostered player
          could result in a forfeit. If a player is listed on more than one
          roster, the player listed will make a decision as to which roster he
          is legally on. This decision is up to the individual player. No player
          can appear in a tournament game for more than one team. If there are
          multiple events going on at one time players are permitted to play for
          one team in each event if so desired; pitching limitations still apply
          to the player. Age divisions within a tournament count as separate
          events. The team’s manager is responsible for all aspects of the
          eligibility of the players on his team and that they meet the
          requirements. A player who is in violation of the age eligibility
          shall be considered an illegal player. If anytime during a tournament
          game, a player is discovered illegal due to an age violation or
          participating on more than one team in the same tournament, the team
          is subject to forfeit. The first team a player participates for is
          their official team. Participating players shall have photocopies of
          their original birth certificates (US) or passports (International) in
          the possession of their team manager. Electronic copies of ID
          documents are accepted. Failure to have a copy of a birth certificate,
          in the event of a protest, could result in suspension for the
          remainder of the event until such a document can be produced. Driver’s
          License is permitted. For events classified by graduation year,
          transcripts from a player's most recent academic semester shall be in
          the possession of their team manager.{" "}
        </li>
        <li>
          Scoring: We recommend that all teams (home and visitor) keep a
          scorebook. The official scorebook and lineup card for the game will be
          kept by tournament staff, in case of emergency where staff can't keep
          a book the home team will be the official scorer. In a situation in
          which tournament staff and the home team are unable to score, the
          visitor would become the official book. All subs should be reported to
          the umpires. To eliminate scoring disputes, scorekeepers should check
          with the official scorer during and after the game. Line up cards must
          be fully filled out with players first name, last name, position, and
          accurate jersey numbers. All substitutes should be listed with
          accurate jersey numbers. Failure to produce a complete lineup card can
          result in games not starting on time. Lineup cards are to be given to
          the official scorekeeper before the home plate meeting.{" "}
        </li>
        <li>
          Sportsmanship: In the interest of maintaining a quality event, the
          tournament committee reserves the right to eject any person from the
          site for unruly or unsportsmanlike behavior. Physical attack on an
          umpire, tournament official, associate director, associate officer,
          and/or any player or fan prior, during, or immediately following a
          game played under the authority of Xposure Sports. Assault on an
          official is a felony in some states. Players, coaches, managers, fans,
          spectators, or sponsors threatening an umpire, tournament official,
          associate director, or associate officer with physical harm. Any
          player, coach, manager, sponsor, fan, spectator, director or officer
          who engages in physical fighting. Using unsportsmanlike conduct or
          abusive language, symbol tactics, or derogatory or unbecoming acts.
          Destruction of property or abuse or failure to pay. Competing under an
          assumed, false and/or altered name. Tournament hosts have the
          authority to eject a player, fans or team anytime during a tournament
          of committing any of the above listed offenses. A player, spectator,
          fan or coach ejection carries removal from that game only. Any
          spectator, coach, manager or player who is ejected for the second time
          during the tournament will be eliminated from participating for the
          remainder of the tournament. Any event that results in physical
          altercation (before, during or after a game) could result in a forfeit
          for both teams. Tournament committee reserves the right to remove
          teams from playoff contention.
        </li>
        <li>
          Bat Specifications: Penalties for illegal bats will be assessed as per
          the NFHS rule book. 1st violation - Batter is pronounced out (if
          discovered before a pitch to the next batter) &amp; head coach is
          restricted to the dugout for the remainder of the game. 2nd violation
          (Same Game) - Batter is pronounced out (if discovered before a pitch
          to the next batter) and the head coach is ejected. Subsequent
          violations: batter is pronounced out (if discovered before a pitch to
          next batter) and head coach is ejected. When a batter is pronounced
          out for violation of bat rules, the defense may take the penalty or
          result of the play. The head coach penalties apply in either case.
          <br />
          Xposure Sports Events will follow the following rules however event
          directors reserve the right to modify rules as needed in the best
          interest of the event. Directors will always rule in the fairest
          possible way.
          <table>
            <thead>
              <tr>
                <th>13U</th>
                <th>14U</th>
                <th>15U-18U</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>Metal bat </div>
                  <div>
                    All bats must have a branding, label, or stamp proving BPF
                    1.15, and length/weight ratio vary by division.
                  </div>
                  <div>USABat, BBCOR and Wood bats are also allowed. </div>
                  <div>Length/weight ratio must be -5 or -3.</div>
                </td>
                <td>
                  <div>Metal/Composite bat or Wood bat</div>
                  <div>Length/weight ratio must be -3 and stamped BBCOR. </div>
                  <div>
                    Wood bats must be composed of a single piece of wood.
                  </div>
                </td>
                <td>
                  <div>Wood bat only</div>
                  <div>
                    Wood bats must be composed of a single piece of wood.
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          The following are the only wood composite bats allowed:
          <ol>
            <b>Rawlings</b>
            <li> 5150 Composite Pro Wood WC5150</li>
            <li>Big Stick R243CH</li>
            <li>Big Stick R243CS</li>
            <li>VELO Composite Wood R110CV</li>
            <li>VELO Composite Wood R110CH</li>
            <li>VELO Composite Wood R110CR</li>
            <li>VELO Composite Wood Y151CV</li>
            <li>Rawlings Composite 243MBS</li>
            <li>Rawlings Composite 243CUS</li>
            <li>Rawlings Composite 110CMB</li>
            <li>Rawlings CompositeY151CB</li>
            <li>Rawlings Maple/Bamboo Composite 271 MBC</li>
            <li>Rawlings Maple/Bamboo Composite SL151G</li>
          </ol>
          <ol>
            <b>Baum</b>
            <li>AAA Pro Maple Baum Bat (Gold &amp; White editions)</li>
            <li>AAA Pro Ash Baum Bat (Gold &amp; White editions)</li>
          </ol>
          <ol>
            <b>DeMarini</b>
            <li>Models: D243, D271, I13, D110</li>
          </ol>
          <ol>
            <b>MacDougall</b>
            <li>PowerWood</li>
          </ol>
          <ol>
            <b>Marucci</b>
            <li>AP5 Hybrid Pro Model</li>
          </ol>
          <ol>
            <b>Pinnacle Sports/BamBooBat</b>
            <li>All models allowed</li>
          </ol>
          <ol>
            <b>
              Safety and integrity of the game are two major factors at Xposure
              Sports events, and with the implementation of the following
              changes, we are taking the next steps in providing an updated and
              premier experience on the baseball field. While these bats are
              currently still certified by their manufacturers, performance of
              these bats over the extended life span continues to become
              increasingly unsafe for our participants. The following bats may
              not be used in Xposure Sports events:
            </b>
            <li>2015 Demarini CF7 (-5)</li>
            <li>2015 Easton XL1 (-5)</li>
            <li>2016 Demarini CF8 (-5)</li>
            <li>2016 Demarini CF8 (-8)</li>
            <li>2016 Demarini CF8 (-10)</li>
            <li>2017 Demarini CF Zen Balanced (-8)**</li>
            <li>2017 Demarini CF Zen SL 2 3/4" (-10)**</li>
            <li>2017 Demarini CF Zen Zero Dark 2 3/4" (-10)**</li>
            <li>2017 Demarini CF Zen (-5)</li>
            <li>Louisville Slugger Blue Meta Composite (33/30 model only)</li>
            <li>
              2018 Dirty South Kamo BB KA 8 (30/22, 31/21, 32/22, 31/23, 32/24
              only)
            </li>
            <li>(Easton) Ghost X (30/20 only - USA Baseball Marked)</li>
          </ol>
        </li>
        <li>
          ALTERED BATS: DEFINITION OF AN ALTERTED BAT “A bat which has been
          subjected to change in its physical makeup outside the original
          manufacturer's product.” At any point, an Xposure Sports Director or
          UIC may request to inspect a bat that is at a Xposure Sports
          sanctioned event. If the owner/user refuses to allow the requesting
          body to inspect the bat the owner/user accepts an automatic three-year
          suspension from Xposure Sports sanctioned events. Alternatively, the
          owner/user can allow the bat to be inspected so a conclusion can be
          formed as to whether the bat was altered. The owner/user of the bat
          have the responsibility of having knowledge whether a bat is altered.
          DAMAGED, WORN, OR ILLEGAL SUBSTANCE ON BATS: Bats that are damaged in
          any way, including but not limited to cracked, warped, missing a
          knob/cap, having a rattle, etc. are not legal for Xposure Sports
          sanctioned events. Bats may not have any certifying markings or
          graphics worn off the bat. Bats that have certifying or identifying
          markings worn off the bat shall be removed from play. No substance
          shall be added to the bat barrel. The barrel of the bat and all
          identifying marks or graphics shall be free of any foreign
          obstruction. If a foreign substance is discovered as being used or
          identifying marks on the bat are not visible the bat shall be removed
          from play.
        </li>
        <li>
          Pitching: The MLB balk rule will be enforced. When a balk is called it
          is NOT a dead ball. A called balk will remain a LIVE ball. The 3B to
          1B pickoff move is legal. Also, in the windup position, a pitcher is
          permitted to have his "free" foot on the rubber, in front of the
          rubber, behind the rubber, or off to the side of the rubber.
        </li>
        <li>
          Enforced Pitching Limitations: All Xposure Sports events will strictly
          follow the Pitch Smart guidelines, unless otherwise noted. Check the
          Pitching Restrictions on our website for the Pitch Smart guidelines.
        </li>
        <li>
          Courtesy Runners: Courtesy runners can be used for the Pitcher and
          Catcher at any time but must be a VALID SUBSTITUTE. If a team is
          batting their entire lineup, then they will NOT be able to use the
          last batted out. In addition, the same substitute CANNOT run for more
          than one position (Pitcher or Catcher) in the same game.
        </li>
        <li>PitchCom: is legal for all Xposure Sports events.</li>
        <li>
          Not covered in the Rules: In the event any item is not covered in
          these rules or NFHS rules, the tournament committee will make the
          fairest possible ruling and that ruling will be final!
        </li>
        <li>
          Field Dimensions
          <ol>
            <li>9U-10U: All games will be played on 46x65 fields.</li>
            <li>11U-12U: All games will be played on 50x70 fields.</li>
            <li>13U-18U: All games will be played on 60x90 fields.</li>
          </ol>
        </li>
        <li>Refunds</li>
        <ul>
          <li>
            If Zero Games are played 75% of entry fee will be returned as a
            credit for a future event run by Xposure Sports.
          </li>
          <li>
            If 25% of your guaranteed tournament games are played 50% of entry
            fee will be returned as a credit for a future event run by Xposure
            Sports.
          </li>
          <li>
            If 33% of your guaranteed tournament games are played 40% of entry
            fee will be returned as a credit for a future event run by Xposure
            Sports.
          </li>
          <li>
            If you play 2 or more of your scheduled games, that is considered a
            full tournament.
          </li>
          <li>
            Credits are valid for 12 months from the time of the canceled event.
          </li>
          <li>
            Teams that pull out within 90 days of an event will not receive any
            refund or credit.
          </li>
        </ul>
      </ol>

      <Link to={`/pitch-smart`} className={style.link}>
        <div className={style.pitchSmart}>
          Click Here To view our Pitch Smart Guidelines
        </div>
      </Link>
    </div>
  );
};

Rules.displayName = "Rules";
export { Rules };
