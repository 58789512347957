// prettier-ignore
import * as React from "react";
import {
  Route,
  BrowserRouter,
  useLocation,
  Routes,
  Navigate,
} from "react-router-dom";
import { Header, Footer } from "../components";
import { IKContext } from "imagekitio-react";
import { DisplayContextProvider } from "./DisplayContext";
import { useSpring, animated } from "react-spring";
import { media, style as s } from "typestyle";
import { configureStore } from "./configureStore";
import { Provider } from "react-redux";
import {
  Home,
  BlogPost,
  Category,
  Rankings,
  Events,
  Registration,
  Tournaments,
  Players,
  Pitchsmart,
  TournamentRegistration,
  Standings,
  Scoreboard,
} from "../pages";
import { screensize } from "../styles";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../store/actions";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const store = configureStore();

const component = s(
  {
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
  },
  media(
    { minWidth: screensize.mdMin },
    {
      paddingTop: 115,
    },
  ),
);

const Page: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  React.useEffect(() => {
    dispatch({ type: ActionTypes.REQUEST_BLOG_POSTS });
    dispatch({ type: ActionTypes.REQUEST_CATEGORIES });
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className={component}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
        <Route path="/tournaments/:slug" element={<Tournaments />} />
        <Route
          path="/tournaments/register"
          element={<TournamentRegistration />}
        />
        <Route path="/rankings" element={<Rankings />} />
        <Route path="/standings" element={<Standings />} />
        <Route path="/scoreboard" element={<Scoreboard />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/pitch-smart" element={<Pitchsmart />} />
        <Route path="/posts/:slug" element={<BlogPost />} />
        <Route path="/categories/:routeCategory" element={<Category />} />
        <Route path="/players/" element={<Players />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
};

export const App = () => {
  const spring = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });
  const stripe = loadStripe(
    "pk_live_51Kb5fHK7R7i61EXPI27bE6OsjKLYSSTITrqccF6k4IrobQ296vmL68HTPfPGaUHLH7j8k0zqXRp6nG8uqmwm6i5p00u0SNmCwe",
  );
  return (
    <Provider store={store}>
      <animated.div style={{ ...spring, position: "relative" }}>
        <BrowserRouter>
          <Elements stripe={stripe}>
            <DisplayContextProvider>
              <IKContext urlEndpoint="https://ik.imagekit.io/yd113v8urnw4/Xposure">
                <Header />
                <Page />
                <Footer />
              </IKContext>
            </DisplayContextProvider>
          </Elements>
          <ToastContainer
            autoClose={5000}
            position="top-right"
            newestOnTop={false}
            hideProgressBar={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        </BrowserRouter>
      </animated.div>
    </Provider>
  );
};
