import * as React from "react";
import * as style from "./Home.style";
import { Banner, PostTile, Icon, RankingsTable } from "../../components";
import { useDispatch, connect } from "react-redux";
import { ActionTypes } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { IKImage } from "imagekitio-react";
import { Title } from "./Title";
import { Tournaments } from "./Tournaments";
import * as Models from "../../models";
import { Popular } from "./Popular";
import { Rankings } from "./Rankings";
import { hooks } from "../../utility";

interface StateProps {
  posts: Models.Post[];
  players: { [year: string]: Models.Player[] };
  schools: { [division: string]: Models.School[] };
  inProgress: boolean;
}

const HomeInternal: React.FC<StateProps> = ({
  posts,
  players,
  schools,
  inProgress,
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
    dispatch({ type: ActionTypes.GET_RANKINGS });
  }, []);
  const display = hooks.useDisplayContext();
  return (
    <>
      <div className={style.component}>
        <IKImage
          alt="Maryland High School Baseball Rankings"
          path="/maryland-high-school-baseball-rankings-2.png"
          className={style.bgImage}
        />
        <div className={style.content}>
          {display.isMobile ? (
            <>
              {/* {!!display.isMobile && (
                <div className={style.mobileTitle}>
                  <div style={{ marginBottom: 5 }}>
                    Welcome to Xposure Sports
                  </div>
                  <div className={style.subTitle}>
                    Your MD Baseball Recruiting Homepage
                  </div>
                </div>
              )} */}
              <Tournaments />
              {posts.slice(0, 3).map((post) => (
                <PostTile key={post.slug} post={post} className={style.post} />
              ))}
              {inProgress ? (
                <Icon.Spinner size={40} />
              ) : (
                <>
                  <RankingsTable
                    players={players}
                    className={style.table}
                    hideSeeMore={true}
                    type="Player"
                    limit={10}
                  />
                  <RankingsTable
                    schools={schools}
                    className={style.table}
                    hideSeeMore={true}
                    type="Team"
                    limit={10}
                  />
                </>
              )}
              {posts.slice(1, 10).map((post) => (
                <PostTile key={post.slug} post={post} className={style.post} />
              ))}
            </>
          ) : (
            <>
              <div className={style.flexSection}>
                <div className={style.mainSection}>
                  <Title />
                </div>
                <div className={style.sidebar}>
                  <Banner title="Latest Tweets" />
                  <div style={{ marginBottom: 10 }}>
                    <a
                      className="twitter-timeline"
                      data-height="420"
                      data-chrome="noscrollbar noheader nofooter"
                      href="https://twitter.com/xposure_sports?ref_src=twsrc%5Etfw"
                    />
                  </div>
                </div>
              </div>
              <Tournaments />
              <Rankings />
              <Popular />
            </>
          )}
        </div>
      </div>
    </>
  );
};

HomeInternal.displayName = "Home";
export const Home = connect((state: RootState) => {
  return {
    posts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
    players: state.rankings.players,
    schools: state.rankings.schools,
    inProgress: state.rankings.inProgress,
  };
}, {})(HomeInternal);
