import * as React from "react";

export interface IDisplayContext {
  viewport: "xs" | "sm" | "md" | "lg";
  windowHeight: number;
  windowWidth: number;
  isMobile: boolean;
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getViewport = (width: number) => {
  if (width < 768) {
    return "xs";
  } else if (width < 1024) {
    return "sm";
  } else if (width < 1200) {
    return "md";
  } else {
    return "lg";
  }
};

const startingDimensions = getWindowDimensions();
const startingViewport = getViewport(startingDimensions.width);

const defaultDisplayContext: IDisplayContext = {
  viewport: startingViewport,
  windowHeight: startingDimensions.height,
  windowWidth: startingDimensions.width,
  isMobile: startingViewport === "xs" || startingViewport === "sm",
};

export const DisplayContext = React.createContext(defaultDisplayContext);

export const DisplayContextProvider: React.FC = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const viewport = getViewport(windowDimensions.width);

  const value: IDisplayContext = {
    viewport: viewport,
    windowHeight: windowDimensions.height,
    windowWidth: windowDimensions.width,
    isMobile: viewport === "xs" || viewport === "sm",
  };

  return (
    <DisplayContext.Provider value={value}>{children}</DisplayContext.Provider>
  );
};
