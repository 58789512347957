import * as React from "react";
import * as style from "./ContactModal.style";
import { Modal } from "../Modal";
import { colors } from "../../styles";
import { hooks } from "../../utility";
import { ContactForm } from "../ContactForm";

interface ComponentProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ContactModal: React.FC<ComponentProps> = ({ isOpen, onRequestClose }) => {
  const display = hooks.useDisplayContext();
  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      className={style.modal}
      closeButtonColor={colors.translucent650}
    >
      <ContactForm className={style.form} onSuccess={onRequestClose} />
    </Modal>
  );
};

ContactModal.displayName = "ContactModal";
export { ContactModal };
