import * as React from "react";
import * as style from "./BlogPost.style";
import { Link, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { ActionTypes } from "../../store/actions";
import { RootState } from "../../store/reducers";
import {
  Banner,
  BlogPost as Post,
  RankingsTable,
  Icon,
  PostTile,
} from "../../components";
import * as Models from "../../models";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";

interface StateProps {
  postsDict: { [slug: string]: Models.Post };
  posts: Models.Post[];
  categories: { [slug: string]: Models.Category };
  players: { [year: string]: Models.Player[] };
  schools: { [division: string]: Models.School[] };
  inProgress: boolean;
}

const BlogPostInternal: React.FC<StateProps> = ({
  postsDict,
  posts,
  players,
  schools,
  inProgress,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const display = hooks.useDisplayContext();
  React.useEffect(() => {
    dispatch({ type: ActionTypes.REQUEST_BLOG_POSTS });
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
  }, []);
  const { slug } = params;
  const post = postsDict[slug];
  const relatedCategories = !!post ? post.categories : [];
  const relatedPosts = React.useMemo(() => {
    const filteredPosts = posts.filter((p) => {
      let match = false;
      relatedCategories.forEach((cat) => {
        if (p.categories.includes(cat)) {
          match = true;
        }
      });
      return match;
    });
    return filteredPosts;
  }, [posts, slug]);

  return (
    <div className={style.component}>
      <IKImage
        alt="Maryland High School Baseball Rankings"
        path="/maryland-high-school-baseball-rankings-2.png"
        className={style.bgImage}
      />
      <div className={style.content}>
        <div className={style.postContainer}>
          <Post post={post} />
          {!!relatedPosts && !!relatedPosts.length && (
            <>
              <Banner title="Related Articles" />
              <div className={style.articles}>
                {relatedPosts.slice(0, 20).map((post) => (
                  <Link
                    to={`/posts/${post.slug}`}
                    className={style.link}
                    key={post.slug}
                  >
                    <div className={style.story}>
                      {post.title.rendered}
                      <span> Kyle Campbell</span>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        {!display.isMobile && (
          <div className={style.sidebar}>
            {!!posts && !!posts.length && (
              <>
                <Banner title="Recent Articles" />
                {posts.map((post) => (
                  <Link
                    to={`/posts/${post.slug}`}
                    key={post.slug}
                    className={style.sidebarLink}
                  >
                    <div className={style.sidebarItem}>
                      {post.title.rendered}
                    </div>
                  </Link>
                ))}
              </>
            )}
            <Banner title="Latest Tweets" className={style.sidebarBanner} />
            <a
              className="twitter-timeline"
              data-height="750"
              data-chrome="noscrollbar noheader nofooter"
              href="https://twitter.com/xposure_sports?ref_src=twsrc%5Etfw"
            />
            {!!inProgress ? (
              <Icon.Spinner size={30} />
            ) : (
              <>
                <RankingsTable
                  schools={schools}
                  type="Team"
                  isSidebar={true}
                  limit={10}
                  className={style.sidebarBanner}
                />
                <RankingsTable
                  players={players}
                  type="Player"
                  isSidebar={true}
                  limit={10}
                  className={style.sidebarBanner}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

BlogPostInternal.displayName = "BlogPost";
export const BlogPost = connect((state: RootState) => {
  return {
    postsDict: state.blog.postsBySlug,
    posts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
    categories: state.categories,
    players: state.rankings.players,
    schools: state.rankings.schools,
    inProgress: state.rankings.inProgress,
  };
}, {})(BlogPostInternal);
