import { combineReducers } from "redux";
import { blog } from "./blog";
import { categories } from "./categories";
import { rankings } from "./rankings";

export const rootReducer = combineReducers({
  blog,
  categories,
  rankings,
});

export type RootState = ReturnType<typeof rootReducer>;
