import axios from "axios";

export const getCategories = () => {
  return axios
    .get(
      `https://xposuresports.wpcomstaging.com/wp-json/wp/v2/categories?per_page=100`,
      {},
    )
    .catch((err) => {
      return err.response;
    });
};
