import * as React from "react";
import * as style from "./Rankings.style";
import { RankingsTable, Icon } from "../../../components";
import { connect } from "react-redux";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import { IKImage } from "imagekitio-react";

interface StateProps {
  players: { [year: string]: Models.Player[] };
  schools: { [division: string]: Models.School[] };
  inProgress: boolean;
}

const RankingsInternal: React.FC<StateProps> = ({
  players,
  schools,
  inProgress,
}) => {
  return (
    <div className={style.component}>
      {inProgress ? (
        <Icon.Spinner size={120} />
      ) : (
        <>
          <RankingsTable
            players={players}
            type="Player"
            className={style.table}
            limit={10}
          />
          <div className={style.logoContainer}>
            <IKImage
              alt="Xposure Sports Logo"
              path="/logo-icon.png"
              width={120}
            />
          </div>
          <RankingsTable
            schools={schools}
            type="Team"
            className={style.table}
            limit={10}
          />
        </>
      )}
    </div>
  );
};

RankingsInternal.displayName = "Rankings";
export const Rankings = connect((state: RootState) => {
  return {
    players: state.rankings.players,
    schools: state.rankings.schools,
    inProgress: state.rankings.inProgress,
  };
}, {})(RankingsInternal);
