import * as React from "react";
import * as style from "./Registration.style";
import { useSearchParams } from "react-router-dom";
import { SignupForm } from "./SignupForm";
import { IKImage } from "imagekitio-react";

const TournamentRegistration: React.FC = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [registered, setRegistered] = React.useState(false);
  const tournamentName = searchParams.get("name");
  return (
    <div className={style.component}>
      <IKImage
        alt="Maryland High School Baseball Rankings"
        path="/maryland-high-school-baseball-rankings-2.png"
        className={style.bgImage}
      />
      <div className={style.content}>
        {!registered ? (
          <SignupForm
            onSuccess={() => setRegistered(true)}
            eventName={tournamentName}
          />
        ) : (
          <div className={style.notificationContent}>
            <div className={style.title}>
              Thank you for registering. Registrations will be processed in the
              order that they are received. You will receive an email
              confirmation within one to two business days stating your
              acceptance into the tournament with a link to complete payment, or
              stating that you have been placed on the waitlist. In order to
              finalize your registration, please complete payment in full as
              soon as possible.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TournamentRegistration.displayName = "TournamentRegistration";
export { TournamentRegistration };
