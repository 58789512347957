import axios from "axios";

export const POSTS_PER_PAGE = 50;
export const getBlogPosts = (page: number) => {
  return axios
    .get(
      `https://xposuresports.wpcomstaging.com/wp-json/wp/v2/posts?per_page=${POSTS_PER_PAGE}&offset=${
        (page - 1) * POSTS_PER_PAGE
      }`,
      {},
    )
    .catch((err) => {
      return err.response;
    });
};
