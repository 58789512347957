import { put, call, takeEvery } from "redux-saga/effects";
import { ActionTypes, RequestBlogPosts } from "../actions";
import * as Api from "../api";
import "regenerator-runtime/runtime";
import { POSTS_PER_PAGE } from "../api/getBlogPosts";

export function* takeRequestBlogPosts(action: RequestBlogPosts) {
  let page = 1;
  let complete = false;
  while (!complete) {
    try {
      const response = yield call(Api.getBlogPosts, page);
      if (response.status === 200) {
        page++;
        complete = response.data.length < POSTS_PER_PAGE;
        yield put({
          type: ActionTypes.REQUEST_BLOG_POSTS_SUCCESS,
          payload: { blogs: response.data },
        });
      } else {
        complete = true;
        yield put({
          type: ActionTypes.REQUEST_BLOG_POSTS_FAILURE,
          payload: {
            message:
              !!response && !!response.data ? response.data.message : undefined,
          },
        });
      }
    } catch (error) {
      complete = true;
      yield put({
        type: ActionTypes.REQUEST_BLOG_POSTS_FAILURE,
        payload: { message: error && error.data && error.data.message },
      });
    }
  }
}

export function* takeRequestBlogPostsSaga() {
  yield takeEvery(ActionTypes.REQUEST_BLOG_POSTS, takeRequestBlogPosts);
}
