import { style as s } from "typestyle";
import * as Style from "../../styles";

export const wrapper = s({
  position: "relative",
});

export const component = s({
  backgroundColor: Style.colors.translucent150,
  height: 70,
  borderRadius: 2,
  display: "flex",
  alignItems: "center",
  padding: "0px 25px",
  fontFamily: Style.text.font.main,
  boxSizing: "border-box",
  border: `1px solid transparent`,
  transition: ".2s ease-in",
  fontSize: 18,
  color: Style.text.color.inverse,
  outline: "none",
  resize: "none",
  zIndex: 2,
});

export const active = s({
  borderColor: Style.colors.darkTranslucent650,
  backgroundColor: Style.colors.translucent400,
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
});

export const hasError = s({
  borderColor: Style.colors.error,
});

export const label = s({
  color: Style.text.color.inverseSubtle,
  fontSize: 18,
  position: "absolute",
  left: 25,
  top: 20,
  zIndex: 1,
  transition: ".2s ease-in",
  textAlign: "left",
});

export const filledLabel = s({
  fontSize: 11,
  top: 6,
  left: 6,
});

export const error = s({
  paddingTop: 5,
  color: Style.colors.error,
  fontSize: Style.text.fontSize.xs,
  textAlign: "left",
});
