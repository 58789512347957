import * as React from "react";
import * as style from "./Registration.style";
import { useSearchParams } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { SignupForm } from "./SignupForm";
import { IKImage } from "imagekitio-react";
import { toast } from "react-toastify";
import {
  AllStarWeekend2023,
  Event,
  FallLeague2023,
  SummerLeague2023,
  UncommittedGames2023,
  XPosureDay2023,
} from "./Events";

const eventDict: Record<string, Event> = {
  "all-star-weekend-2023": AllStarWeekend2023,
  "summer-league-2023": SummerLeague2023,
  "xposure-day-2023": XPosureDay2023,
  "fall-league-2023": FallLeague2023,
  "uncommitted-games-2023": UncommittedGames2023,
};

const Registration: React.FC = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [price, setPrice] = React.useState<string | undefined>();
  const eventName = searchParams.get("name");
  const event = eventDict[eventName];
  React.useEffect(() => {
    if (event.playerTypes.length === 1) {
      setPrice(event.playerTypes[0].priceId);
    }
  }, [event]);
  const success = searchParams.get("result");
  const stripe = useStripe();
  const submitPayment = async () => {
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      toast.error(
        "Stripe failed to load, but your registration information has been captured. Please contact us to complete payment.",
      );
      return;
    } else if (price === "paid") {
      toast.success(
        "Your registration information has been captured. Thanks for signing up!",
      );
    } else if (!price) {
      toast.error(
        "There is an error with Stripe on our end, but your registration information has been captured. Please contact us to complete payment.",
      );
      return;
    }
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price, // Replace with the ID of your price
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: `https://xposuresports.com/register?name=${eventName}&result=success`,
      cancelUrl: `https://xposuresports.com/register?name=${eventName}&result=failure`,
    });
    if (!!error) {
      toast.error(
        "Stripe failed to redirect you, but your registration information has been captured. Please contact us to complete payment.",
      );
    }
  };
  return (
    <div className={style.component}>
      <IKImage
        alt="Maryland High School Baseball Rankings"
        path="/maryland-high-school-baseball-rankings-2.png"
        className={style.bgImage}
      />
      <div className={style.content}>
        {!event ? (
          <div className={style.title}>
            There has been an error on our end. Please contact us directly to
            sign up.
          </div>
        ) : !success ? (
          <SignupForm
            onSuccess={submitPayment}
            eventName={event.name}
            onPlayerTypeChange={(priceId) => setPrice(priceId)}
            playerTypes={event.playerTypes}
            showRegistrationType={event.playerTypes.length > 1}
          />
        ) : (
          <div className={style.notificationContent}>
            <div className={style.title}>
              {success === "success"
                ? `Congratulations! You are officially registered for ${eventName}. Keep an eye on your email for further information regarding the event.`
                : `I'm sorry, your registration payment did not go through. Please try again.`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Registration.displayName = "Registration";
export { Registration };
