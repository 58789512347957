import * as React from "react";
import * as style from "./Footer.style";
import { IKImage } from "imagekitio-react";
import { classes } from "typestyle";
import { Link } from "../Link";
import * as Icons from "../Icon";
import { hooks } from "../../utility";

export interface ComponentProps {
  className?: string;
}

const Footer: React.FC<ComponentProps> = ({ className }) => {
  const display = hooks.useDisplayContext();
  return (
    <div className={classes(className, style.component)}>
      <div className={style.content}>
        <Link to="/">
          <IKImage
            alt="Xposure Sports Logo"
            path="/full-x-logo.png"
            width={display.isMobile ? 150 : 226}
            className={style.logo}
          />
        </Link>
        <div className={style.icons}>
          {/* <a
            href="https://tiktok.com/xposureSports"
            target="_blank"
            className={style.icon}
          >
            <Icons.TikTok size={30} />
          </a> */}
          <a
            href="https://www.instagram.com/xposure_sports/"
            target="_blank"
            rel="noopener noreferrer"
            className={style.icon}
          >
            <Icons.Instagram size={30} />
          </a>
          {/* <a
            href="https://www.facebook.com/xposureSports"
            target="_blank"
            rel="noopener noreferrer"
            className={style.icon}
          >
            <Icons.Facebook size={30} />
          </a> */}
          <a
            href="https://twitter.com/xposure_sports"
            target="_blank"
            className={style.icon}
          >
            <Icons.Twitter size={30} />
          </a>
        </div>
        <div className={style.items}>
          <Link to={`/`}>
            <div>Home</div>
          </Link>
          <div>
            <a href="https://play.xposuresports.com">
              <div>Tournaments</div>
            </a>
          </div>
          <div>
            <a href="https://play.xposuresports.com">
              <div>Events</div>
            </a>
          </div>
          <Link to={`/rankings`}>
            <div>Rankings</div>
          </Link>
          <Link to={`/posts/about-xposure-sports`}>
            <div>About</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

Footer.displayName = "Footer";
export { Footer };
