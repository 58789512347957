export const screensize = {
	/*** 767 */
	xsMax: 767,
	/*** 768 */
	smMin: 768,
	/*** 1023 */
	smMax: 1023,
	/*** 1024 */
	mdMin: 1024,
	/*** 1199 */
	mdMax: 1199,
	/*** 1200 */
	lgMin: 1200,
};
