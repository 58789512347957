import axios from "axios";

export const getRankings = () => {
  return axios
    .get(
      "https://a0h5kc6bn3.execute-api.us-east-1.amazonaws.com/xposure-rankings",
    )
    .catch((err) => {
      return err.response;
    });
};
