import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  backgroundColor: "#5C7E96",
  whiteSpace: "nowrap",
  $nest: {
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#85b5d6",
    },
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: ".2s ease-in",
  textDecoration: "none",
  outline: "none",
  color: Style.colors.white,
  padding: `12px 18px`,
  fontSize: Style.text.fontSize.medium,
  borderRadius: "2px",
  border: "none",
  cursor: "pointer",
});

export const dark = s({
  backgroundColor: Style.colors.black1,
  borderColor: Style.colors.translucent150,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent650,
    },
  },
  background: "none",
});

export const rounded = s({
  borderRadius: 16,
});

export const disabled = s({
  backgroundColor: "#333333",
  color: "rgba(255, 255, 255, .5)",
  background: "none",
  cursor: "auto",
  $nest: {
    "&:hover": {
      backgroundColor: "#333333",
    },
  },
});

export const spinner = s({
  height: 18,
  width: 18,
  marginLeft: 8,
});
