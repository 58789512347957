import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    marginBottom: 25,
    fontFamily: Style.text.font.main,
    fontSize: 28,
    fontWeight: 700,
    textTransform: "uppercase",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25)",
    background: "radial-gradient(50% 50% at 50% 50%, #0B0C13 0%, #222643 100%)",
    padding: "20px 10px",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      fontSize: 28,
      background: "#F9F9F9",
      marginBottom: 60,
      padding: 0,
    },
  ),
);

export const blueSection = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      clipPath: "polygon(0 0, 100% 0%, calc(100% - 50px) 100%, 0% 100%)",
      background:
        "radial-gradient(50% 50% at 50% 50%, #010101 0%, rgba(47, 52, 85, 0.99) 100%)",
      flex: 3,
    },
  ),
);

export const play = s(
  {
    flex: 1,
    letterSpacing: "-2%",
    color: "#F4F4F4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 32,
    lineHeight: "40px",
    fontFamily: Style.text.font.third,
    fontWeight: 900,
    marginBottom: 15,
    textAlign: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 50,
      lineHeight: "60px",
      marginRight: 6,
      justifyContent: "flex-start",
      padding: "20px 0px 30px 30px",
      marginBottom: 0,
      textAlign: "left",
    },
  ),
);

export const divider = s(
  {
    height: "100%",
    background: "#EEEEEE",
    width: 6,
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      display: "none",
    },
  ),
);

export const tournaments = s(
  {
    flex: 1,
    display: "flex",
    fontStyle: "italic",
    justifyContent: "center",
    alignItems: "center",
    color: Style.colors.primary,
    fontSize: 28,
    lineHeight: "34px",
    cursor: "pointer",
    marginBottom: 5,
    padding: 5,
    background: Style.colors.translucent150,
    $nest: {
      "&:hover": {
        color: `${Style.colors.primary}80`,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 45,
      lineHeight: "53px",
      marginBottom: 0,
      padding: 0,
      background: "none",
      height: "100%",
    },
  ),
);

export const events = s(
  {
    flex: 2,
    backgroundColor: "#F9F9F9",
    display: "flex",
    justifyContent: "center",
    fontStyle: "italic",
    alignItems: "center",
    color: Style.colors.second,
    fontSize: 28,
    lineHeight: "34px",
    cursor: "pointer",
    padding: 5,
    $nest: {
      "&:hover": {
        color: `${Style.colors.second}80`,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 45,
      lineHeight: "53px",
      padding: 0,
      marginLeft: -48,
      clipPath: "polygon(50px 0, 100% 0%, 100% 100%, 0% 100%)",
      height: "100%",
    },
  ),
);

export const link = s({
  flex: 1,
  textDecoration: "none",
});
