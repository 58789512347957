import { media, style as s } from "typestyle";
import * as Style from "../../../styles";
export const mainSection = s(
  {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.73) 100%)",
    padding: 20,
    width: `calc(100% - 40px)`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 calc(75% - 25px)",
    },
  ),
);

export const header = s({
  fontSize: 32,
  marginBottom: 20,
  lineHeight: "36px",
});

export const event = s({
  marginBottom: 30,
  width: "100%",
});

export const eventHeader = s({
  textAlign: "center",
  maxWidth: "80%",
  margin: "auto",
});

export const eventName = s({
  fontSize: 40,
  fontWeight: 700,
  lineHeight: "42px",
  color: Style.colors.second,
  marginBottom: 10,
});

export const location = s({
  fontSize: 20,
  fontWeight: 500,
  fontStyle: "italic",
  letterSpacing: "-1%",
  marginBottom: 30,
});

export const cost = s({
  fontSize: 22,
  fontWeight: 700,
  color: "#727272",
  marginBottom: 15,
});

export const format = s(
  {
    color: Style.colors.primary,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "16px",
    marginBottom: 15,
    $nest: {
      span: {
        fontSize: 16,
        color: Style.colors.darkTranslucent750,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
    },
  ),
);

export const divisions = s(
  {
    color: "#E70000",
    textTransform: "uppercase",
    fontWeight: 700,
    marginBottom: 10,
    lineHeight: "16px",
    fontSize: 16,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
    },
  ),
);

export const link = s({
  textDecoration: "none",
});

export const register = s({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  textTransform: "uppercase",
  backgroundColor: Style.colors.second,
  color: Style.text.color.inverse,
  fontSize: 24,
  fontWeight: 700,
  padding: 10,
  marginTop: 30,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.translucent400,
      color: Style.colors.second,
      border: `1px solid ${Style.colors.second}`,
    },
  },
});

export const description = s({
  color: "#6F6F74",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 500,
  fontFamily: Style.text.font.third,
});

export const mainInfo = s(
  {
    fontSize: 24,
    color: "#8A8A8A",
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
      padding: "0px 50px",
    },
  ),
);

export const subTitle = s({
  fontSize: 20,
  marginBottom: 20,
});

export const subInfo = s(
  {
    fontSize: 14,
    color: "#8A8A8A",
    marginBottom: 30,
    fontStyle: "italic",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
      padding: "0px 50px",
    },
  ),
);

export const rulesList = s({
  $nest: {
    li: {
      fontSize: 14,
      marginBottom: 5,
    },
  },
});
