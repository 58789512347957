import { Link } from "react-router-dom";
import * as React from "react";
import { classes } from "typestyle";
import * as style from "./NavDropdown.style";
import { hooks } from "../../utility";

interface NavOption {
  label: string;
  slug: string;
}

export interface ComponentProps {
  className?: string;
  titleClassName?: string;
  navOptions: NavOption[];
  title: string;
}

/**
 * Link component
 * Wrapper for the Link imported component
 */
const NavDropdown: React.FC<ComponentProps> = ({
  className,
  navOptions,
  title,
  titleClassName,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const display = hooks.useDisplayContext();
  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      className={classes(className, style.component)}
    >
      <div className={classes(titleClassName, style.title)}>
        <div>{title}</div>
        {!!isOpen &&
          (display.isMobile ? (
            <div className={style.mobileDropDown}>
              {navOptions.map((navOption) => (
                <Link
                  to={navOption.slug}
                  key={navOption.label}
                  className={style.mobileItem}
                >
                  <div>{navOption.label}</div>
                </Link>
              ))}
            </div>
          ) : (
            <div className={style.dropDown}>
              {navOptions.map((navOption) => (
                <Link to={navOption.slug} key={navOption.label}>
                  <div className={style.dropDownItem}>{navOption.label}</div>
                </Link>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

NavDropdown.displayName = "NavDropdown";
export { NavDropdown };
