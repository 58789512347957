import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  borderTop: `6px solid ${Style.colors.second}`,
  fontFamily: Style.text.font.main,
  $nest: {
    table: {
      borderCollapse: "collapse",
      border: `1px solid ${Style.colors.darkTranslucent650}`,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      background: "#FAFAFA",
    },
    td: {
      border: `1px solid ${Style.colors.darkTranslucent650}`,
      fontSize: Style.text.fontSize.smallMed,
      textAlign: "center",
      padding: 10,
      $nest: {
        "&:first-child": {
          color: "#EA0000",
          width: 40,
        },
        "&:nth-child(even)": {
          flex: 3,
        },
        "&:even-child(odd)": {
          flex: 1,
        },
      },
    },
    th: {
      textTransform: "uppercase",
      fontWeight: 700,
      padding: "20px 10px",
      fontSize: Style.text.fontSize.small,
    },
  },
});

export const header = s(
  {
    fontSize: 28,
    fontStyle: "italic",
    padding: "20px 30px",
    background: "#FEFEFE",
    color: Style.colors.second,
    fontWeight: 800,
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    alignItems: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 40,
    },
  ),
);

export const subHeader = s({
  background: "#F3F3F3",
  display: "flex",
  border: `solid ${Style.colors.darkTranslucent650}`,
  borderBottom: "none",
  color: Style.colors.second,
});

export const subHeaderTab = s(
  {
    flex: 1,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: `1px solid ${Style.colors.darkTranslucent650}`,
    padding: `10px 0px`,
    textTransform: "uppercase",
    $nest: {
      "&:hover": {
        color: `${Style.colors.second}80`,
      },
      "&:last-child": {
        borderRight: "none",
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `20px 0px`,
    },
  ),
);

export const selectedSubHeaderTab = s({
  background: "radial-gradient(50% 50% at 50% 50%, #000000 0%, #282B44 100%)",
  color: Style.colors.primary,
  $nest: {
    "&:hover": {
      color: Style.colors.primary,
    },
  },
});

export const table = s({
  width: "100%",
});

export const seeMore = s({
  background: "#F3F3F3",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `solid ${Style.colors.darkTranslucent650}`,
  borderTop: "none",
  color: Style.colors.darkTranslucent650,
  textDecoration: "none",
  textTransform: "uppercase",
  letterSpacing: "2.5px",
  transition: ".2s ease-in",
  fontSize: Style.text.fontSize.xs,
  padding: 10,
  $nest: {
    "&:hover": {
      color: Style.colors.black4,
      background: "#FAFAFA",
    },
  },
});

export const sidebar = s({
  background: "#FFFFFF",
  $nest: {
    [`.${header}`]: {
      fontSize: 22,
      fontWeight: 700,
      padding: 10,
      color: "#3A3A3A",
    },
    [`.${subHeaderTab}`]: {
      padding: "10px 0px",
    },
    td: {
      fontSize: Style.text.fontSize.xs,
      padding: `10px 5px`,
    },
    th: {
      padding: "10px 5px",
      fontSize: 14,
    },
  },
});
