import * as React from "react";
import * as style from "./SignupForm.style";
import { Button, TextInput, Dropdown } from "../../../../components";
import { hooks } from "../../../../utility";
import { classes } from "typestyle";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";

const SERVICE_ID = "service_c8mq82g";
const TEMPLATE_ID = "template_9vfk6k7";
const USER_ID = "user_BYHTvWHdurWUyvjL7ZRHK";
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const mobileRegex = /^[0-9]*$/;

/**
 * - Charm City Classic 16U (September 16-17)
- Charm City Classic 13U & 14U (September 23-24) - *Note: 2 Age Divisions
- Charm City Classic 15U (September 30 - October 1)
- Southern Maryland Blue Crab Bash 14U/15U & 16U-18U (October 7-8) *Note: 2 Age Divisions
- Xposure Fall World Series 16U & 17/18U (October 14-15) *Note: 2 Age Divisions
- Xposure Fall World Series 14U & 15U (October 21-22) *Note: 2 Age Divisions
 */

const tournaments: Record<string, string[]> = {
  "Charm City Classic 17/18U (September 9-10)": ["17/18U"],
  "Charm City Classic 16U (September 16-17)": ["16U"],
  "Charm City Classic 13U & 14U (September 23-24)": ["13U", "14U"],
  "Charm City Classic 15U (September 30 - October 1)": ["15U"],
  "Southern Maryland Blue Crab Bash 15U/16U & 17U-18U (October 7-8)": [
    "15U/16U",
    "17U-18U",
  ],
  "Xposure Fall World Series 16U & 17/18U (October 14-15)": ["16U", "17/18U"],
  "Xposure Fall World Series 14U & 15U (October 21-22)": ["14U", "15U"],
};

interface ComponentProps {
  className?: string;
  onSuccess?: () => void;
  eventName?: string;
}

const SignupForm: React.FC<ComponentProps> = ({
  className,
  onSuccess,
  eventName,
}) => {
  const display = hooks.useDisplayContext();
  const preSelectedEvent = tournaments[eventName];

  const [tournament, setTournament] = React.useState<string | undefined>(
    !!preSelectedEvent ? eventName : undefined,
  );
  const [teamName, setTeamName] = React.useState<string | undefined>();
  const [email, setEmail] = React.useState<string | undefined>();
  const [mobile, setMobile] = React.useState<string | undefined>();
  const [city, setCity] = React.useState<string | undefined>();
  const [state, setState] = React.useState<string | undefined>();
  const [division, setDivision] = React.useState<string | undefined>(
    !!preSelectedEvent ? "N/A" : undefined,
  );
  const [coachName, setCoachName] = React.useState<string | undefined>();
  const [additionalContact, setAdditionalContact] = React.useState<
    string | undefined
  >();

  const [tournamentError, setTournamentError] = React.useState<
    string | undefined
  >();
  const [teamNameError, setTeamNameError] = React.useState<
    string | undefined
  >();
  const [emailError, setEmailError] = React.useState<string | undefined>();
  const [mobileError, setMobileError] = React.useState<string | undefined>();
  const [cityError, setCityError] = React.useState<string | undefined>();
  const [stateError, setStateError] = React.useState<string | undefined>();
  const [divisionError, setDivisionError] = React.useState<
    string | undefined
  >();
  const [throwsError, setThrowsError] = React.useState<string | undefined>();
  const [coachNameError, setCoachNameError] = React.useState<
    string | undefined
  >();

  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string | undefined>();

  const onSubmit = () => {
    if (!tournament) {
      setTournamentError("Please select a tournament");
    }
    if (!teamName) {
      setTeamNameError("Please enter your team name");
    }
    if (!email) {
      setEmailError("Please enter your email");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
    }
    if (!mobile || mobile.length < 10) {
      setMobileError("Please provide a valid mobile number");
    }
    if (!city) {
      setCityError("Please enter your city");
    }
    if (!state) {
      setStateError("Please enter your state");
    }
    if (!division) {
      setDivisionError("Please enter your division");
    }
    if (!coachName) {
      setCoachNameError("Please enter your coach's full name");
    }
    if (
      !!tournament &&
      !!teamName &&
      !!email &&
      emailRegex.test(email) &&
      (!mobile || mobile.length === 10) &&
      !!city &&
      !!state &&
      !!division &&
      !!coachName
    ) {
      setSubmitError("");
      setLoading(true);
      try {
        emailjs
          .send(
            SERVICE_ID,
            TEMPLATE_ID,
            {
              team_name: teamName,
              coach_name: coachName,
              email,
              mobile,
              city,
              state,
              division,
              tournament,
              additionalContact,
            },
            USER_ID,
          )
          .then(
            (response) => {
              if (onSuccess) {
                onSuccess();
              }
              setLoading(false);
            },
            (error) => {
              toast.warning(
                "Something seems to have gone wrong - please refresh the page and try again",
              );
              setLoading(false);
            },
          )
          .catch(() => {
            toast.warning(
              "We couldnt capture your information. Please try again on a laptop or desktop computer. If this continues, please contact us",
            );
            setLoading(false);
          });
      } catch (e) {
        console.log(e);
        setLoading(false);
        toast.warning(
          "Something seems to have gone wrong. Please refresh the page and try again. If you are on mobile, please try again on desktop.",
        );
      }
    } else {
      setSubmitError("Please fix the above errors then try again");
    }
  };

  return (
    <>
      <div className={classes(style.component, className)}>
        <div className={style.content}>
          <div className={style.subTitle}>Play Where it Matters</div>
          <div className={style.title}>
            Register for{" "}
            {!!preSelectedEvent ? eventName : `Xposure Sports Tournaments`}
          </div>
          <div className={style.inputSection}>
            {!preSelectedEvent && (
              <Dropdown
                wrapperClassName={classes(style.dropdown)}
                options={Object.keys(tournaments).map((val) => ({
                  label: val,
                  value: val,
                }))}
                label="Tournament*"
                onChange={(option) => {
                  setTournament(option.label);
                }}
                currentOption={{ label: tournament, value: tournament }}
                error={tournamentError}
              />
            )}
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Team Name*"
              value={teamName}
              onChange={(val?: string) => {
                setTeamName(val);
                setTeamNameError("");
              }}
              error={teamNameError}
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Email*"
              value={email}
              onChange={(val: string) => {
                setEmail(val);
                setEmailError("");
              }}
              inputType="email"
              error={emailError}
            />
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Mobile*"
              value={mobile}
              onChange={(val: string) => {
                if (mobileRegex.test(val) && val.length < 11) {
                  setMobile(val);
                  setMobileError("");
                }
              }}
              inputType="tel"
              error={mobileError}
            />
            {(!preSelectedEvent || preSelectedEvent.length > 1) && (
              <Dropdown
                wrapperClassName={classes(style.dropdown)}
                options={(tournaments[tournament] || []).map((val) => ({
                  label: val,
                  value: val,
                }))}
                label="Division"
                onChange={(option) => {
                  setDivision(option.label);
                }}
                currentOption={{ label: division, value: division }}
              />
            )}
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="City*"
              value={city}
              onChange={(val?: string) => {
                setCity(val);
                setCityError("");
              }}
              error={cityError}
            />
            <TextInput
              wrapperClassName={style.inputWrapper}
              className={style.input}
              label="State*"
              value={state}
              onChange={(val?: string) => {
                setState(val);
                setStateError("");
              }}
              error={stateError}
            />
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Coach Name*"
              value={coachName}
              onChange={(val?: string) => {
                setCoachName(val);
                setCoachNameError("");
              }}
              error={coachNameError}
            />
          </div>
          <div className={style.inputSection}>
            <TextInput
              wrapperClassName={style.largeInputWrapper}
              className={style.input}
              label="Additional Team Contact Name | Phone Number | Email Address"
              value={additionalContact}
              onChange={(val?: string) => {
                setAdditionalContact(val);
              }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              className={style.button}
              rounded={false}
              disabled={
                !!tournamentError ||
                !!emailError ||
                !!mobileError ||
                !!teamNameError ||
                !!cityError ||
                !!stateError ||
                !!throwsError ||
                !!divisionError ||
                !!coachNameError
              }
              loading={loading}
              onClick={onSubmit}
            >
              Sign Up
            </Button>
            <div className={style.error}>{submitError}</div>
          </div>
        </div>
      </div>
    </>
  );
};

SignupForm.displayName = "SignupForm";
export { SignupForm };
