import * as React from "react";
import * as style from "./Category.style";
import { Link, useParams } from "react-router-dom";
import { Banner, Icon, PostTile, RankingsTable } from "../../components";
import { connect } from "react-redux";
import { RootState } from "../../store/reducers";
import * as Models from "../../models";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";

interface StateProps {
  allPosts: Models.Post[];
  categories: { [slug: string]: Models.Category };
  players: { [year: string]: Models.Player[] };
  schools: { [division: string]: Models.School[] };
  inProgress: boolean;
}

const CategoryInternal: React.FC<StateProps> = ({
  allPosts,
  categories,
  players,
  schools,
  inProgress,
}) => {
  const { routeCategory } = useParams();
  const category = categories[routeCategory];
  const posts = React.useMemo(() => {
    return !!category && !!allPosts
      ? allPosts.filter((post) => post.categories.includes(category.id))
      : [];
  }, [category]);
  React.useEffect(() => {
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
  }, []);
  const display = hooks.useDisplayContext();
  return !!category ? (
    <div className={style.component}>
      <IKImage
        alt="Maryland High School Baseball Rankings"
        path="/maryland-high-school-baseball-rankings-2.png"
        className={style.bgImage}
      />
      {display.isMobile ? (
        <div className={style.mobileContent}>
          <Banner title={category.name} className={style.banner} />
          <div className={style.container}>
            {posts.map((post) => (
              <PostTile key={post.slug} post={post} />
            ))}
          </div>
        </div>
      ) : (
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.postContainer}>
              <Banner title={category.name} className={style.banner} />
              {!!posts && !!posts.length ? (
                <div style={{ marginBottom: 40 }}>
                  <div className={style.flexSection}>
                    <div className={style.featuredPost}>
                      <Link to={`/posts/${posts[0].slug}`}>
                        <img
                          src={posts[0].jetpack_featured_media_url}
                          className={style.featuredImage}
                          alt={posts[0].title.rendered}
                        />
                        <div className={style.featuredContent}>
                          <div className={style.featuredTitle}>
                            {posts[0].title.rendered}
                          </div>
                          <div className={style.author}>Kyle Campbell</div>
                        </div>
                      </Link>
                    </div>
                    <div className={style.secondarySection}>
                      {posts.length > 1 && (
                        <div
                          className={style.flexSection}
                          style={{ marginBottom: 35 }}
                        >
                          <PostTile className={style.topPost} post={posts[1]} />
                          {posts.length > 2 && (
                            <PostTile
                              className={style.topPost}
                              post={posts[2]}
                            />
                          )}
                        </div>
                      )}
                      {posts.length > 3 && (
                        <div className={style.flexSection}>
                          <PostTile className={style.topPost} post={posts[3]} />
                          {posts.length > 4 && (
                            <PostTile
                              className={style.topPost}
                              post={posts[4]}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={style.morePosts}>
                    {posts.slice(5).map((post) => (
                      <PostTile
                        className={style.post}
                        post={post}
                        key={post.slug}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  style={{ marginBottom: 40, fontSize: 28, lineHeight: "32px" }}
                >
                  Sorry, there are no articles in this category at this time.
                  Please check back soon.
                </div>
              )}
              <Banner className={style.banner} title="Recent Articles" />
              <div className={style.articles}>
                {allPosts.slice(0, 20).map((post) => (
                  <Link
                    to={`/posts/${post.slug}`}
                    className={style.link}
                    key={post.slug}
                  >
                    <div className={style.story}>
                      {post.title.rendered}
                      <span> Kyle Campbell</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className={style.sidebar}>
              {!!allPosts && !!allPosts.length && (
                <>
                  <Banner title="Recent Articles" />
                  {allPosts.slice(0, 10).map((post) => (
                    <Link
                      to={`/posts/${post.slug}`}
                      key={post.slug}
                      className={style.sidebarLink}
                    >
                      <div className={style.sidebarItem}>
                        {post.title.rendered}
                      </div>
                    </Link>
                  ))}
                </>
              )}
              <Banner title="Latest Tweets" className={style.sidebarBanner} />
              <a
                className="twitter-timeline"
                data-height="750"
                data-chrome="noscrollbar noheader nofooter"
                href="https://twitter.com/xposure_sports?ref_src=twsrc%5Etfw"
              />
              {!!inProgress ? (
                <Icon.Spinner size={30} />
              ) : (
                <>
                  <RankingsTable
                    schools={schools}
                    type="Team"
                    limit={10}
                    isSidebar={true}
                    className={style.sidebarBanner}
                  />
                  <RankingsTable
                    players={players}
                    type="Player"
                    limit={10}
                    isSidebar={true}
                    className={style.sidebarBanner}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Icon.Spinner size={30} />
  );
};

CategoryInternal.displayName = "Category";
export const Category = connect((state: RootState) => {
  return {
    allPosts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
    categories: state.categories,
    players: state.rankings.players,
    schools: state.rankings.schools,
    inProgress: state.rankings.inProgress,
  };
}, {})(CategoryInternal);
