import * as React from "react";
import * as style from "./PostTile.style";
import { Link } from "react-router-dom";
import * as Models from "../../models";
import { classes } from "typestyle";

interface ComponentProps {
  className?: string;
  post: Models.Post;
}

const PostTile: React.FC<ComponentProps> = ({ className, post }) => {
  return (
    <div key={post.slug} className={classes(style.component, className)}>
      <Link to={`/posts/${post.slug}`}>
        <div className={style.container}>
          <img
            className={style.postImage}
            src={post.jetpack_featured_media_url}
          />
          <div className={style.postContent}>
            <div className={style.postTitle}>{post.title.rendered}</div>
            <div className={style.author}>Kyle Campbell</div>
          </div>
          <div className={style.overlay} />
        </div>
      </Link>
    </div>
  );
};

PostTile.displayName = "PostTile";
export { PostTile };
