import { put, call, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "../actions";
import * as Api from "../api";
import "regenerator-runtime/runtime";

export function* takeGetRankings() {
  try {
    const response = yield call(Api.getRankings);
    if (response.status === 200 && !!response.data) {
      yield put({
        type: ActionTypes.GET_RANKINGS_SUCCESS,
        payload: {
          ...response.data,
        },
      });
    } else {
      yield put({
        type: ActionTypes.GET_RANKINGS_FAILURE,
        payload: {
          message:
            !!response && !!response.data ? response.data.message : undefined,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_RANKINGS_FAILURE,
      payload: { message: error && error.data && error.data.message },
    });
  }
}

export function* takeGetRankingsSaga() {
  yield takeEvery([ActionTypes.GET_RANKINGS], takeGetRankings);
}
