import { media, style as s } from "typestyle";
import * as Style from "../../../../styles";

export const component = s(
  {
    padding: "20px 15px",
    background: "radial-gradient(50% 50% at 50% 50%, #0B0C13 0%, #222643 100%)",
    width: "100%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 85,
    },
  ),
);

export const content = s({
  margin: "auto",
  textAlign: "center",
});

export const subTitle = s({
  fontWeight: 700,
  fontFamily: Style.text.font.third,
  color: Style.colors.primary,
  textTransform: "uppercase",
  marginBottom: 20,
  letterSpacing: "2px",
  fontSize: 16,
});

export const title = s(
  {
    fontWeight: 700,
    color: "#EEEEEE",
    fontFamily: Style.text.font.main,
    fontSize: Style.text.fontSize.medium,
    lineHeight: "36px",
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.large,
      lineHeight: "48px",
    },
  ),
);

export const inputSection = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
    },
  ),
);

export const inputWrapper = s(
  {
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: 1,
      marginRight: 15,
      $nest: {
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  ),
);

export const largeInputWrapper = s(
  {
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: 2,
      marginRight: 15,
      $nest: {
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  ),
);

export const dropdown = s(
  {
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginRight: 15,
      flex: 1,
      $nest: {
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  ),
);

export const dropdown1 = s({
  zIndex: 24,
});

export const dropdown2 = s({
  zIndex: 23,
});

export const dropdown3 = s({
  zIndex: 22,
});

export const dropdown4 = s({
  zIndex: 21,
});

export const input = s({
  width: "100%",
});

export const messageInput = s(
  {
    width: "100%",
    height: `150px !important`,
    paddingTop: `25px !important`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      height: `250px !important`,
    },
  ),
);

export const button = s({
  margin: "20px auto",
  fontSize: 18,
  fontWeight: 600,
  fontFamily: Style.text.font.third,
});

export const toast = s({
  padding: `20px 30px`,
  fontSize: Style.text.fontSize.medium,
  maxWidth: "auto",
  fontFamily: Style.text.font.main,
});

export const error = s({
  color: Style.colors.error,
  fontSize: Style.text.fontSize.xs,
  textAlign: "center",
});

export const checkBoxError = s({
  color: Style.colors.error,
  fontSize: Style.text.fontSize.xs,
  textAlign: "left",
  paddingTop: 5,
});

export const checkbox = s({
  textAlign: "left",
  marginBottom: 15,
  color: Style.colors.white,
});
