import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    fontFamily: Style.text.font.main,
    boxSizing: "border-box",
    position: "relative",
    paddingTop: 70,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 20,
    },
  ),
);

export const bgImage = s({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  zIndex: 1,
});

export const content = s({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  zIndex: 2,
});

export const mainSection = s(
  {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.73) 100%)",
    padding: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 calc(75% - 25px)",
    },
  ),
);

export const header = s({
  fontSize: 32,
  marginBottom: 20,
});

export const event = s(
  {
    marginBottom: 30,
    width: "100%",
    alignItems: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
    },
  ),
);

export const eventImage = s({
  flex: "0 0 200px",
  height: 140,
  marginRight: 20,
  marginBottom: 30,
});

export const eventHeader = s(
  {
    marginBottom: 5,
    height: "100%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  ),
);

export const headerSection = s({
  // flex: "0 0 30%",
  marginBottom: 10,
});

export const eventName = s({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: "26px",
  color: Style.colors.second,
  marginBottom: 5,
  maxWidth: 300,
});

export const location = s({
  fontSize: 14,
  color: "#ABABAB",
  marginBottom: 5,
  maxWidth: 300,
});

export const cost = s({
  fontSize: 16,
  fontWeight: 700,
  color: "#727272",
});

export const format = s(
  {
    color: Style.colors.darkGreen,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "16px",
    marginBottom: 15,
    $nest: {
      span: {
        fontSize: 16,
        color: Style.colors.darkTranslucent750,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
      paddingRight: 20,
    },
  ),
);

export const divisions = s(
  {
    color: "#E70000",
    textTransform: "uppercase",
    fontWeight: 700,
    lineHeight: "16px",
    fontSize: 16,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
    },
  ),
);

export const register = s({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  textTransform: "uppercase",
  backgroundColor: Style.colors.second,
  color: Style.text.color.inverse,
  fontSize: 24,
  fontWeight: 700,
  width: 175,
  marginBottom: 10,
  padding: 10,
  transition: ".2s ease-in",
  boxSizing: "border-box",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.translucent400,
      color: Style.colors.second,
      border: `1px solid ${Style.colors.second}`,
    },
  },
});

export const description = s({
  color: "#6F6F74",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 500,
  fontFamily: Style.text.font.third,
});

export const mainInfo = s(
  {
    fontSize: 20,
    fontWeight: 500,
    fontStyle: "italic",
    letterSpacing: "-1%",
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
      padding: "0px 50px",
    },
  ),
);

export const infoBox = s(
  {
    backgroundColor: "#FEFEFE",
    padding: "10px 20px",
    fontSize: 16,
    lineHeight: "22px",
    fontWeight: 700,
    fontStyle: "italic",
    letterSpacing: "-2%",
    color: "#414141",
    marginBottom: 30,
    flex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 calc(50% - 25px)",
    },
  ),
);

export const infoBoxTitle = s({
  fontSize: 34,
  lineHeight: "40px",
  marginBottom: 20,
  color: "#000000",
});

export const sidebar = s({
  flex: "0 0 calc(25% - 25px)",
  paddingTop: 20,
});

export const sidebarHeader = s({
  marginTop: 20,
});

export const articles = s({
  background:
    "linear-gradient(180deg, #F2F2F2 0%, rgba(250, 250, 250, 0.85) 0.01%, rgba(255, 255, 255, 0.74) 100%)",
  padding: 20,
  boxShadow: "0px 2px 2px 0px #00000040",
});

export const link = s({
  textDecoration: "none",
});

export const story = s({
  color: "#292929",
  marginBottom: 15,
  fontSize: "18px",
  $nest: {
    span: {
      color: "#818181",
      paddingLeft: 5,
    },
    "&:hover": {
      cursor: "pointer",
      color: Style.colors.primary,
    },
  },
});

export const shareContainer = s({
  padding: `20px 0`,
  marginBottom: 20,
  borderTop: "solid 1px #E1E1E1",
  borderBottom: "solid 1px #E1E1E1",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const shareSection = s({
  display: "flex",
});

export const share = s({
  textTransform: "uppercase",
  color: "#7E7E7E",
  fontSize: 18,
  marginRight: 20,
});

export const icon = s({
  width: 20,
  marginRight: 5,
  cursor: "pointer",
  $nest: {
    svg: {
      transition: ".2s ease-in",
      stroke: Style.colors.second,
      fill: Style.colors.second,
      $nest: {
        "&:hover": {
          stroke: `${Style.colors.second}70`,
          fill: `${Style.colors.second}70`,
        },
      },
    },
  },
});

export const holder = s({
  color: Style.colors.second,
  fontSize: 32,
  fontWeight: 700,
  marginBottom: 20,
});
