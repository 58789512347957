import * as Models from "../models";

export enum ActionTypes {
  /**
   * BLOG
   */
  REQUEST_BLOG_POSTS = "BLOG/REQUEST_BLOG_POSTS",
  REQUEST_BLOG_POSTS_SUCCESS = "BLOG/REQUEST_BLOG_POSTS_SUCCESS",
  REQUEST_BLOG_POSTS_FAILURE = "BLOG/REQUEST_BLOG_POSTS_FAILURE",
  /**
   * CATEGORIES
   */
  REQUEST_CATEGORIES = "CATEGORIES/REQUEST_CATEGORIES",
  REQUEST_CATEGORIES_SUCCESS = "CATEGORIES/REQUEST_CATEGORIES_SUCCESS",
  REQUEST_CATEGORIES_FAILURE = "CATEGORIES/REQUEST_CATEGORIES_FAILURE",
  /**
   * RANKINGS
   */
  GET_RANKINGS = "RANKINGS/GET_RANKINGS",
  GET_RANKINGS_SUCCESS = "RANKINGS/GET_RANKINGS_SUCCESS",
  GET_RANKINGS_FAILURE = "RANKINGS/GET_RANKINGS_FAILURE",
}

export interface RequestBlogPosts {
  type: ActionTypes.REQUEST_BLOG_POSTS;
  payload: {};
}

export interface RequestBlogPostsSuccess {
  type: ActionTypes.REQUEST_BLOG_POSTS_SUCCESS;
  payload: {
    blogs: Models.Post[];
  };
}

export interface RequestBlogPostsFailure {
  type: ActionTypes.REQUEST_BLOG_POSTS_FAILURE;
  payload: {
    message?: string;
  };
}

export interface RequestCategories {
  type: ActionTypes.REQUEST_CATEGORIES;
  payload: {};
}

export interface RequestCategoriesSuccess {
  type: ActionTypes.REQUEST_CATEGORIES_SUCCESS;
  payload: {
    categories: Models.Category[];
  };
}

export interface RequestCategoriesFailure {
  type: ActionTypes.REQUEST_CATEGORIES_FAILURE;
  payload: {
    message?: string;
  };
}

export interface GetRankings {
  type: ActionTypes.GET_RANKINGS;
}

export interface GetRankingsSuccess {
  type: ActionTypes.GET_RANKINGS_SUCCESS;
  payload: {
    players: { [year: string]: Models.Player[] };
    schools: { [division: string]: Models.School[] };
  };
}

export interface GetRankingsFailure {
  type: ActionTypes.GET_RANKINGS_FAILURE;
  payload: {
    message?: string;
  };
}

export type Actions =
  | RequestBlogPosts
  | RequestBlogPostsSuccess
  | RequestBlogPostsFailure
  | RequestCategories
  | RequestCategoriesSuccess
  | RequestCategoriesFailure
  | GetRankings
  | GetRankingsSuccess
  | GetRankingsFailure;
