import React from "react";
import * as style from "./BlogPost.style";
import * as Icon from "../Icon";
import { classes } from "typestyle";
import { Post } from "../../models";

interface ComponentProps {
  className?: string;
  post: Post;
}

export const BlogPost: React.FC<ComponentProps> = ({ className, post }) => {
  React.useEffect(() => {
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
  }, []);
  return (
    <div className={classes(className, style.component)}>
      {!post ? (
        <Icon.Spinner size={80} />
      ) : (
        <>
          <img
            className={style.featuredImage}
            src={post.jetpack_featured_media_url}
            alt={post.title.rendered}
          />
          <div className={style.content}>
            {/* <div className={style.shareContainer}>
              <div className={style.share}>Share</div>
              <a href="link here" target="_blank" className={style.icon}>
                <Icon.Twitter />
              </a>
              <a href="link here" target="_blank" className={style.icon}>
                <Icon.Facebook />
              </a>
              <a href="link here" target="_blank" className={style.icon}>
                <Icon.Instagram />
              </a>
            </div> */}
            <div className={style.title}>{post.title.rendered}</div>
            <div className={style.authorDate}>
              Kyle Campbell{" "}
              <span className={style.authorDateDot}>&middot;</span>{" "}
              {new Date(post.date).toLocaleDateString()}
            </div>
            <div
              className={style.innerHtml}
              dangerouslySetInnerHTML={{ __html: post.content.rendered }}
            />
            {/* <div className={style.shareContainer}>
              <div className={style.share}>Share</div>
              <a href="link here" target="_blank" className={style.icon}>
                <Icon.Twitter />
              </a>
              <a href="link here" target="_blank" className={style.icon}>
                <Icon.Facebook />
              </a>
              <a href="link here" target="_blank" className={style.icon}>
                <Icon.Instagram />
              </a>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};
