import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    fontFamily: Style.text.font.main,
    boxSizing: "border-box",
    position: "relative",
    paddingTop: 70,
    marginBottom: 100,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 20,
    },
  ),
);

export const batRules = s({
  fontSize: 28,
  fontWeight: 600,
  marginBottom: 20,
  textAlign: "center",
  textTransform: "uppercase",
});

export const details = s({
  fontSize: 20,
  marginBottom: 15,
  fontWeight: 500,
});

export const formatImgContainer = s({
  width: "60%",
  margin: "auto",
  marginBottom: 20,
});

export const formatImg = s({
  width: "100%",
});

export const rulesList = s({
  listStyleType: "decimal",
  $nest: {
    table: {
      border: "solid 1px black",
      borderCollapse: "collapse",
      tableLayout: "fixed",
      width: "100%",
    },
    td: {
      border: "solid 1px black",
      borderCollapse: "collapse",
      padding: 10,
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordWrap: "break-word",
      $nest: {
        div: {
          marginBottom: 10,
        },
      },
    },
    th: {
      border: "solid 1px black",
      borderCollapse: "collapse",
      padding: 10,
      textAlign: "center",
      wordWrap: "break-word",
    },
    li: {
      marginBottom: 10,
      fontSize: 16,
      lineHeight: `18px`,
      $nest: {
        ol: {
          paddingTop: 10,
          listStyleType: "lower-alpha",
          $nest: {
            li: {
              $nest: {
                ol: {
                  paddingTop: 10,
                  listStyleType: "lower-roman",
                },
              },
            },
          },
        },
      },
    },
  },
});

export const link = s({
  textDecoration: "none",
});

export const pitchSmart = s({
  fontSize: 24,
  fontWeight: 600,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  textTransform: "uppercase",
  backgroundColor: Style.colors.second,
  color: Style.text.color.inverse,
  padding: 10,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.translucent400,
      color: Style.colors.second,
      border: `1px solid ${Style.colors.second}`,
    },
  },
});

export const iscoreImg = s({
  width: 36,
  height: 36,
  marginRight: 20,
});
