import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    position: "relative",
    backgroundColor: "#FEFEFE",
    fontFamily: Style.text.font.main,
    fontSize: 20,
    fontWeight: 700,
    zIndex: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `0px 80px`,
    },
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      padding: `0px 100px`,
    },
  ),
);

export const content = s(
  {
    borderTop: "2px solid #E1E1E1",
    padding: `30px 10px`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: "30px 0px",
      display: "flex",
    },
  ),
);

export const logo = s({
  marginRight: 50,
  marginBottom: 20,
});

export const icons = s({
  display: "flex",
  marginBottom: 10,
});

export const icon = s({
  width: 40,
  marginRight: 5,
  cursor: "pointer",
  $nest: {
    svg: {
      transition: ".2s ease-in",
      stroke: Style.colors.second,
      fill: Style.colors.second,
      $nest: {
        "&:hover": {
          stroke: `${Style.colors.second}70`,
          fill: `${Style.colors.second}70`,
        },
      },
    },
  },
});

export const items = s(
  {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    color: Style.colors.second,
    $nest: {
      a: {
        textDecoration: "none",
        margin: `0px 25px 10px 0px`,
        color: Style.colors.second,
        transition: ".2s ease-in",
        $nest: {
          "&:hover": {
            color: Style.colors.third,
          },
          "&:last-child": {
            marginRight: 0,
          },
        },
      },
    },
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  ),
);
