import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  marginBottom: 35,
  minHeight: 450,
});
export const mainSection = s({
  padding: 20,
  background: Style.colors.translucent880,
});

export const flexSection = s({
  display: "flex",
  justifyContent: "space-between",
});

export const topPost = s({
  flex: "0 0 calc(50% - 10px)",
  marginBottom: 0,
});

export const bottomPost = s({
  flex: "0 0 calc(25% - 10px)",
  marginBottom: 0,
});

export const featuredPost = s({
  flex: "0 0 calc(50% - 10px)",
  transition: ".2s ease-in",
  cursor: "pointer",
  fontSize: 0,
  $nest: {
    a: {
      color: "inherit",
      textDecoration: "none",
    },
  },
});

export const featuredImage = s({
  width: "100%",
});

export const featuredContent = s({
  padding: `10px 0px`,
});

export const featuredTitle = s({
  fontSize: 37,
  lineHeight: "43px",
  fontWeight: 700,
  marginBottom: 10,
  color: "#343434",
});

export const author = s({
  fontSize: 10,
  color: "#898989",
  fontFamily: Style.text.font.third,
  marginBottom: 10,
});

export const secondarySection = s({
  flex: "0 0 calc(50% - 10px)",
});

export const secondaryImage = s({
  width: "100%",
  marginBottom: 10,
});

export const secondaryTitle = s({
  fontSize: 15,
  lineHeight: "18px",
  fontWeight: 500,
  marginBottom: 10,
  color: "#343434",
  fontFamily: Style.text.font.third,
  padding: "0px 10px",
});
