import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    fontFamily: Style.text.font.main,
    boxSizing: "border-box",
    position: "relative",
    paddingTop: 70,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 20,
    },
  ),
);

export const bgImage = s({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  zIndex: 1,
});

export const content = s({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  zIndex: 2,
});

export const notificationContent = s(
  {
    padding: "20px 15px",
    background: "radial-gradient(50% 50% at 50% 50%, #0B0C13 0%, #222643 100%)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 85,
    },
  ),
);

export const title = s(
  {
    fontWeight: 700,
    color: "#EEEEEE",
    fontFamily: Style.text.font.main,
    fontSize: Style.text.fontSize.medium,
    lineHeight: "36px",
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.large,
      lineHeight: "48px",
    },
  ),
);
