import { Actions, ActionTypes } from "../actions";
import { Category } from "../../models";

export interface CategoryStore {
  [slug: string]: Category;
}

export const initialState: CategoryStore = {};

export const categories = (
  state: CategoryStore = initialState,
  action: Actions,
): CategoryStore => {
  switch (action.type) {
    case ActionTypes.REQUEST_CATEGORIES_SUCCESS: {
      const newCategories: { [slug: string]: Category } = {};
      action.payload.categories.forEach((category) => {
        newCategories[category.slug] = category;
      });
      return newCategories;
    }
    default:
      return state;
  }
};
