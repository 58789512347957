import { put, call, takeEvery } from "redux-saga/effects";
import { ActionTypes, RequestCategories } from "../actions";
import * as Api from "../api";
import "regenerator-runtime/runtime";

export function* takeRequestCategories(action: RequestCategories) {
  try {
    const response = yield call(Api.getCategories);
    if (response.status === 200) {
      yield put({
        type: ActionTypes.REQUEST_CATEGORIES_SUCCESS,
        payload: { categories: response.data },
      });
    } else {
      yield put({
        type: ActionTypes.REQUEST_CATEGORIES_SUCCESS,
        payload: {
          message:
            !!response && !!response.data ? response.data.message : undefined,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.REQUEST_CATEGORIES_FAILURE,
      payload: { message: error && error.data && error.data.message },
    });
  }
}

export function* takeRequestCategoriesSaga() {
  yield takeEvery(ActionTypes.REQUEST_CATEGORIES, takeRequestCategories);
}
