import { media, style as s } from "typestyle";
import * as Style from "../../styles";
import { title } from "../NavDropdown/NavDropdown.style";

export const component = s({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  background: `linear-gradient(180deg, ${Style.colors.second} 80%, ${Style.colors.second} 100%)`,
  zIndex: 10,
  padding: "12px 50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: Style.text.font.main,
  borderBottom: `1px solid #B9B9B9`,
  maxHeight: 100,
});

export const contentLeft = s({
  position: "relative",
  display: "flex",
  alignItems: "center",
});

export const contentRight = s({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: 76,
});

export const items = s({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
});

export const icon = s({
  display: "flex",
  alignItems: "center",
  width: 40,
  height: 40,
  marginRight: 5,
  cursor: "pointer",
  $nest: {
    "&:last-child": {
      marginRight: 0,
    },
    svg: {
      transition: ".2s ease-in",
      stroke: Style.colors.translucent880,
      fill: Style.colors.translucent880,
      $nest: {
        "&:hover": {
          stroke: `${Style.colors.translucent650}`,
          fill: `${Style.colors.translucent650}`,
        },
      },
    },
  },
});

export const item = s({
  fontWeight: 500,
  textTransform: "uppercase",
  marginRight: 25,
  fontSize: 18,
  $nest: {
    "&:last-child": {
      marginRight: 0,
    },
    a: {
      textDecoration: "none",
      color: Style.text.color.inverse,
      $nest: {
        "&:hover": {
          color: Style.text.color.inverseSubtle,
        },
      },
    },
    [`.${title}`]: {
      $nest: {
        "&:hover": {
          color: Style.text.color.inverseSubtle,
        },
      },
      color: Style.text.color.inverse,
    },
  },
});
