import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  fontFamily: Style.text.font.main,
  boxSizing: "border-box",
  position: "relative",
  background: "#FEFEFE",
  padding: 20,
});

export const bgImage = s({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,
});

export const content = s(
  {
    position: "relative",
    zIndex: 2,
    paddingTop: 90,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      paddingTop: 0,
    },
  ),
);

export const flexSection = s({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const sidebar = s({
  flex: "0 0 calc(25% - 25px)",
  background: "#FFFFFF",
  paddingTop: 20,
});

export const sidebarHeader = s({
  marginTop: 20,
});

export const mainSection = s(
  {
    flex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 calc(75% - 25px)",
      padding: 20,
      background: Style.colors.translucent880,
    },
  ),
);

export const sidebarLink = s({
  textDecoration: "none",
});

export const sidebarItem = s({
  fontSize: 16,
  padding: 10,
  fontWeight: 500,
  color: Style.colors.black2,
  borderBottom: `1px solid ${Style.colors.darkTranslucent500}`,
  textDecoration: "none",
  $nest: {
    "&:hover": {
      color: "#3D3D3D",
    },
  },
});

export const table = s({
  width: "100%",
  marginBottom: 30,
});

export const title = s({
  fontSize: 37,
  fontWeight: 700,
  marginBottom: 10,
});

export const header = s(
  {
    display: "flex",
    alignContent: "center",
    border: `1px solid black`,
    fontSize: 24,
    fontWeight: 600,
    color: "black",
    textTransform: "uppercase",
    marginBottom: 10,
    backgroundColor: Style.colors.translucent850,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 32,
      marginBottom: 30,
    },
  ),
);

export const headerTab = s(
  {
    padding: 15,
    flex: 1,
    transition: ".2s ease-in",
    textAlign: "center",
    cursor: "pointer",
    $nest: {
      "&:first-child": {
        borderRight: `1px solid black`,
      },
      "&:hover": {
        backgroundColor: Style.colors.darkTranslucent750,
        color: "white",
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 30,
    },
  ),
);

export const selectedHeaderTab = s(
  {
    padding: 15,
    flex: 1,
    transition: ".2s ease-in",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: Style.colors.second,
    fontWeight: 600,
    color: Style.colors.primary,
    $nest: {
      "&:hover": {
        backgroundColor: Style.colors.second,
        color: Style.colors.primary,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 30,
    },
  ),
);
export const articles = s({
  background:
    "linear-gradient(180deg, #F2F2F2 0%, rgba(250, 250, 250, 0.85) 0.01%, rgba(255, 255, 255, 0.74) 100%)",
  padding: 20,
  boxShadow: "0px 2px 2px 0px #00000040",
});

export const link = s({
  textDecoration: "none",
});

export const story = s({
  color: "#292929",
  marginBottom: 15,
  fontSize: "18px",
  $nest: {
    span: {
      color: "#818181",
      paddingLeft: 5,
    },
    "&:hover": {
      cursor: "pointer",
      color: Style.colors.primary,
    },
  },
});

export const banner = s({
  marginBottom: 20,
});

export const scoreboard = s({
  fontSize: 20,
  fontWeight: 500,
  textDecoration: "underline",
  color: "black",
  marginBottom: 4,
});
