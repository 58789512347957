import * as React from "react";
import * as style from "./Popular.style";
import { Banner, PostTile } from "../../../components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";

interface StateProps {
  posts: Models.Post[];
}

const PopularInternal: React.FC<StateProps> = ({ posts }) => {
  const [tileCount, setTileCount] = React.useState(12);
  return (
    <div className={style.component}>
      <Banner className={style.banner} title="All Articles" />
      <div className={style.posts}>
        {!!posts && !!posts.length && (
          <>
            {posts.slice(0, tileCount).map((post) => (
              <PostTile className={style.post} post={post} key={post.slug} />
            ))}
            {tileCount < posts.length && (
              <div
                onClick={() => setTileCount(tileCount + 10)}
                className={style.seeMore}
              >
                See more
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

PopularInternal.displayName = "Popular";
export const Popular = connect((state: RootState) => {
  return {
    posts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
  };
}, {})(PopularInternal);
