import * as React from "react";
import * as style from "./Banner.style";
import { classes } from "typestyle";
import { IKImage } from "imagekitio-react";

interface ComponentProps {
  title: string;
  className?: string;
}

const Banner: React.FC<ComponentProps> = ({ title, className }) => {
  return (
    <div className={classes(style.component, className)}>
      <div>{title}</div>
      <IKImage alt="Xposure Sports Logo" path="/x-logo-green.png" height={42} />
    </div>
  );
};

Banner.displayName = "Banner";
export { Banner };
