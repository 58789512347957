import { Link as RouterLink } from "react-router-dom";
import * as React from "react";
import { classes } from "typestyle";
import * as style from "./Link.style";

export interface ComponentProps {
  className?: string;
  to: string;
}

/**
 * Link component
 * Wrapper for the Link imported component
 */
const Link: React.FC<ComponentProps & { children?: React.ReactNode }> = ({
  className,
  to,
  children,
}) => {
  return (
    <RouterLink className={classes(className, style.component)} to={to}>
      {children}
    </RouterLink>
  );
};

Link.displayName = "Link";

export { Link };
