import * as React from "react";
import { classes } from "typestyle";
import * as style from "./Dropdown.style";

interface Option {
  label: string;
  value: any;
}
export interface ComponentProps {
  wrapperClassName?: string;
  className?: string;
  options: Option[];
  currentOption?: Option;
  label: string;
  onChange: (option?: Option) => void;
  error?: string;
}

/**
 * Link component
 * Wrapper for the Link imported component
 */
const Dropdown: React.FC<ComponentProps> = ({
  className,
  wrapperClassName,
  label,
  options,
  onChange,
  currentOption,
  error,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  React.useEffect(() => {
    setIsOpen(false);
  }, [currentOption]);
  return (
    <div
      className={wrapperClassName}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <div
        onClick={() => setIsOpen(true)}
        className={classes(
          className,
          style.component,
          !!error ? style.hasError : "",
        )}
      >
        <div className={style.title}>
          <div
            className={classes(
              !!currentOption && !!currentOption.label
                ? style.filledLabel
                : style.label,
            )}
          >
            {label}
          </div>
          {!!currentOption && !!currentOption.label && (
            <div className={style.title}>{currentOption.label}</div>
          )}
          <div className={style.arrow}>&#x25BC;</div>
        </div>
        {!!isOpen && (
          <div className={style.dropDown}>
            {options.map((option) => (
              <div
                key={option.label}
                className={style.dropDownItem}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(option);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      {!!error && <div className={style.error}>{error}</div>}
    </div>
  );
};

Dropdown.displayName = "Dropdown";
export { Dropdown };
