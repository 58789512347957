import * as React from "react";
import { Link } from "../Link";
import { classes } from "typestyle";
import * as Icons from "../Icon";
import * as style from "./Header.style";
import { MobileHeader } from "./MobileHeader";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";
import { NavDropdown } from "../NavDropdown";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}

/**
 * Header component
 * Page Header
 */
const Header: React.FC<ComponentProps> = ({ className }) => {
  const display = hooks.useDisplayContext();

  return display.isMobile ? (
    <MobileHeader />
  ) : (
    <div className={classes(className, style.component)}>
      <div className={style.contentLeft}>
        <Link to="/">
          <IKImage
            alt="Xposure Sports Logo"
            path="/full-green-logo.png"
            height={68}
            width={284}
          />
        </Link>
      </div>
      <div className={style.contentRight}>
        <div className={style.items}>
          {/* <a
            href="https://tiktok.com/xposureSports"
            target="_blank"
            className={style.icon}
          >
            <Icons.TikTok size={30} />
          </a> */}
          <a
            href="https://www.instagram.com/xposure_sports/"
            target="_blank"
            rel="noopener noreferrer"
            className={style.icon}
          >
            <Icons.Instagram size={30} />
          </a>
          {/* <a
            href="https://www.facebook.com/xposure_sports/"
            target="_blank"
            rel="noopener noreferrer"
            className={style.icon}
          >
            <Icons.Facebook size={30} />
          </a> */}
          <a
            href="https://twitter.com/xposure_sports"
            target="_blank"
            className={style.icon}
          >
            <Icons.Twitter size={30} />
          </a>
        </div>
        <div className={style.items}>
          <div className={style.item}>
            <Link to={`/`}>
              <div>Home</div>
            </Link>
          </div>
          <div className={style.item}>
            <a href="https://play.xposuresports.com">
              <div>Tournaments</div>
            </a>
          </div>
          <div className={style.item}>
            <a href="https://play.xposuresports.com">
              <div>Events</div>
            </a>
          </div>
          <NavDropdown
            className={style.item}
            title="Leagues"
            navOptions={[
              // {
              //   label: "Spring Training",
              //   slug: "/posts/xposure-sports-spring-training-league",
              // },
              {
                label: "Summer League",
                slug: "/posts/xposure-sports-summer-league",
              },
              {
                label: "Fall League",
                slug: "/posts/2023-xposure-sports-fall-league",
              },
            ]}
          />
          <div className={style.item}>
            <a target="_blank" href="https://xposuresportsmedia.com">
              <div>Media</div>
            </a>
          </div>
          {/* <div className={style.item}>
            <Link to={`/players`}>
              <div>Player Search</div>
            </Link>
          </div> */}
          <NavDropdown
            className={style.item}
            title="High School HQ"
            navOptions={[
              { label: "Schedules & Scores", slug: "/scoreboard" },
              { label: "Standings", slug: "/standings" },
              { label: "State Top 25", slug: "/categories/state-top-25" },
              { label: "Power Rankings", slug: "/categories/power-rankings" },
              {
                label: "Spring Break Showdown",
                slug: "/posts/2023-spring-break-showdown",
              },
              { label: "Scout Notes", slug: "/categories/scout-notes" },
              {
                label: "News",
                slug: "/categories/high-school-content",
              },
            ]}
          />
          <div className={style.item}>
            <Link to={`/rankings`}>
              <div>Rankings</div>
            </Link>
          </div>
          <div className={style.item}>
            <a href="https://play.xposuresports.com/players" target="_blank">
              <div>Profiles</div>
            </a>
          </div>
          <div className={style.item}>
            <Link to={`/posts/about-xposure-sports`}>
              <div>About</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.displayName = "Header";
export { Header };
