import * as React from "react";
import * as style from "./Tournaments.style";
import { Link } from "react-router-dom";

const Tournaments: React.FC = () => {
  return (
    <div className={style.component}>
      <div className={style.blueSection}>
        <div className={style.play}>Play where it matters</div>
        <div className={style.divider} />
        <Link
          to="/posts/2023-xposure-sports-summer-tournament-schedule"
          className={style.link}
        >
          <div className={style.tournaments}>Tournaments</div>
        </Link>
      </div>

      <a href="https://play.xposuresports.com" className={style.link}>
        <div className={style.events}>Events</div>
      </a>
    </div>
  );
};

Tournaments.displayName = "Tournaments";
export { Tournaments };
