// prevents script injection
export const validateAlphanumeric = (val: string) => {
  const re = /[^0-9a-zA-Z\s()[\]_.,"'-:@]/g;
  return val.replace(re, "");
};

// RFC 5322 email regex
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// 6 - 20 characters, must include at least one lowercase, uppercase, number
export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
// 10 digit - numbers only
export const phoneRegex = /^\d{10}$/;
