import * as Models from "../../models";
import { Actions, ActionTypes } from "../actions";

export interface RankingsStore {
  players: { [year: string]: Models.Player[] } | null;
  schools: { [division: string]: Models.School[] } | null;
  inProgress: boolean;
}

export const initialState: RankingsStore = {
  players: null,
  schools: null,
  inProgress: false,
};

export const rankings = (
  state: RankingsStore = initialState,
  action: Actions,
): RankingsStore => {
  switch (action.type) {
    case ActionTypes.GET_RANKINGS: {
      return { ...state, inProgress: true };
    }
    case ActionTypes.GET_RANKINGS_SUCCESS: {
      return { ...action.payload, inProgress: false };
    }
    case ActionTypes.GET_RANKINGS_FAILURE: {
      return { ...state, inProgress: false };
    }
    default:
      return state;
  }
};
