import * as React from "react";
import * as style from "./TournamentDetails";
import { Rules } from "../Rules";
import { Link } from "react-router-dom";

interface ComponentProps {
  className?: string;
}

const BattleRoyale: React.FC<ComponentProps> = ({ className }) => {
  return (
    <>
      <div className={style.event}>
        <div>
          <div className={style.eventHeader}>
            <div className={style.eventName}>Labor Day Battle Royale</div>
            <div className={style.location}>September 2nd - 5th</div>
            <div className={style.cost}>
              The inaugural Labor Day Battle Royale will be held at Kelley Park
              and other surrounding Montgomery County Area Fields
            </div>
            <div className={style.divisions}>Divisions</div>
            <div className={style.divisions}>
              <b>15U:</b> 2026 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2007
            </div>
            <div className={style.divisions}>
              <b>16U:</b> 2025 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2006
            </div>
            <div className={style.divisions}>
              <b>17U:</b> 2024 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2005
            </div>
            <div className={style.divisions}>
              <b>18U:</b> 2023 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2004
            </div>
            <div>
              <Link to={`/tournaments/register`} className={style.link}>
                <div className={style.register}>Request Invite</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.mainInfo}>
        Pool Play with Top Two Teams Advancing to the Championship
      </div>
      <div className={style.subInfo}>
        *Xposure Sports reserves the right to change the format based on the
        number of teams registered for each age group and or combine 15U and
        16U.
      </div>
      <div className={style.subTitle}>Pool Play Tie-Breaking Procedures</div>
      <ol>
        <li>Pool play overall record (winning percentage)</li>
        <li>If two teams are tied - Head to Head winner</li>
        <li>
          If three teams are tied - If one team has defeated both other teams,
          that team advances, If not go to #4.
        </li>
        <li>Total runs allowed in pool play.</li>
        <li>
          If still tied, Total runs allowed, subtracting the game with the most
          runs allowed in pool play.
        </li>
        <li>
          If still tied, Total runs allowed, minus the two games with the most
          runs allowed in pool play.
        </li>
        <li>If still tied, Total runs scored in pool play.</li>
        <li>
          If still tied, Highest total run differential (Total runs scored minus
          runs allowed) in pool play.
        </li>
        <li>
          If still tied, Runs scored inning by inning starting with the first
          game of pool play until one team has more runs after a complete
          inning.
        </li>
        <li>
          If still tied, Runs allowed inning by inning starting with the first
          game of pool play until one team has less runs after a complete
          inning.
        </li>
        <li>Coin toss</li>
      </ol>
      <Rules isPoolPlay={true} />
    </>
  );
};

BattleRoyale.displayName = "BattleRoyale";
export { BattleRoyale };
