import * as React from "react";

import { Link } from "../Link";
import { IKImage } from "imagekitio-react";
import { Burger } from "../Burger";
import { NavDropdown } from "../NavDropdown";
import * as style from "./MobileHeader.style";
import { colors } from "../../styles";
import { Modal } from "../Modal";
import { useLocation } from "react-router-dom";

export interface ComponentProps {
  className?: string;
}
/**
 * Header component
 * Page Header
 */
const MobileHeader: React.FC<ComponentProps> = ({ className }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setMenuOpen(false);
  }, [location]);
  return (
    <>
      <div className={style.component} id="header">
        <Link to="/">
          <IKImage
            alt="Xposure Sports Logo"
            path="/full-logo.png"
            height={45}
          />
        </Link>
        <Burger
          isOpen={menuOpen}
          onClick={() => setMenuOpen(!menuOpen)}
          color={colors.primary}
          className={style.burger}
        />
      </div>
      <Modal
        isOpen={menuOpen}
        onRequestClose={() => setMenuOpen(false)}
        className={style.modal}
        closeButtonColor={colors.primary}
      >
        <Link to="/" className={style.link}>
          <div className={style.navItem}>Home</div>
        </Link>

        <a href="https://play.xposuresports.com">
          <div className={style.navItem}>Tournaments</div>
        </a>
        <a href="https://play.xposuresports.com">
          <div className={style.navItem}>Events</div>
        </a>
        <NavDropdown
          titleClassName={style.dropDown}
          title="High School HQ"
          navOptions={[
            { label: "Scores", slug: "/categories/scores" },
            { label: "State Top 25", slug: "/categories/state-top-25" },
            { label: "Power Rankings", slug: "/categories/power-rankings" },
            {
              label: "Spring Break Showdown",
              slug: "/posts/2023-spring-break-showdown",
            },
            { label: "Scout Notes", slug: "/categories/scout-notes" },
            {
              label: "News",
              slug: "/categories/high-school-content",
            },
          ]}
        />
        <Link to={`/rankings`}>
          <div className={style.navItem}>Rankings</div>
        </Link>
        <a href="https://play.xposuresports.com/players" target="_blank">
          <div className={style.navItem}>Profiles</div>
        </a>
        <Link to={`/posts/about-xposure-sports`}>
          <div className={style.navItem}>About</div>
        </Link>
        <NavDropdown
          titleClassName={style.dropDown}
          title="Leagues"
          navOptions={[
            // {
            //   label: "Spring Training",
            //   slug: "/posts/xposure-sports-spring-training-league",
            // },
            {
              label: "Summer League",
              slug: "/posts/xposure-sports-summer-league",
            },
            {
              label: "Fall League",
              slug: "/posts/2023-xposure-sports-fall-league",
            },
          ]}
        />
        <a target="_blank" href="https://xposuresportsmedia.com">
          <div className={style.navItem}>Media</div>
        </a>
      </Modal>
    </>
  );
};

MobileHeader.displayName = "MobileHeader";

export { MobileHeader };
