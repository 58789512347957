import EmailSVG from "./svgs/email.svg";
import HtmlBracketsSVG from "./svgs/htmlBrackets.svg";
import CloseSVG from "./svgs/close.svg";
import SpinnerSVG from "./svgs/spinner.svg";
import FacebookSVG from "./svgs/facebook.svg";
import GoogleSVG from "./svgs/google.svg";
import InstagramSVG from "./svgs/instagram.svg";
import TwitterSVG from "./svgs/twitter.svg";
import TikTokSVG from "./svgs/tiktok.svg";
import { Icon } from "./Icon";

export const Email = Icon(EmailSVG);
export const Facebook = Icon(FacebookSVG);
export const Google = Icon(GoogleSVG);
export const HtmlBrackets = Icon(HtmlBracketsSVG);
export const Close = Icon(CloseSVG);
export const Spinner = Icon(SpinnerSVG);
export const Instagram = Icon(InstagramSVG);
export const Twitter = Icon(TwitterSVG);
export const TikTok = Icon(TikTokSVG);
