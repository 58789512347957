import * as React from "react";
import * as style from "./Title.style";
import { Link } from "react-router-dom";
import { Icon, PostTile } from "../../../components";
import { connect } from "react-redux";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import { hooks } from "../../../utility";

interface StateProps {
  posts: Models.Post[];
}

const TitleInternal: React.FC<StateProps> = ({ posts }) => {
  return (
    <div className={style.component}>
      {!!posts && !!posts.length ? (
        <>
          <div className={style.mainSection}>
            <div className={style.flexSection}>
              <div className={style.featuredPost}>
                <Link to={`/posts/${posts[0].slug}`}>
                  <img
                    src={posts[0].jetpack_featured_media_url}
                    className={style.featuredImage}
                    alt={posts[0].title.rendered}
                  />
                  <div className={style.featuredContent}>
                    <div className={style.featuredTitle}>
                      {posts[0].title.rendered}
                    </div>
                    <div className={style.author}>Kyle Campbell</div>
                  </div>
                </Link>
              </div>
              <div className={style.secondarySection}>
                <div className={style.flexSection} style={{ marginBottom: 35 }}>
                  {posts.length > 1 && (
                    <div className={style.topPost}>
                      <PostTile post={posts.at(1)} />
                    </div>
                  )}
                  {posts.length > 2 && (
                    <div className={style.topPost}>
                      <PostTile post={posts.at(2)} />
                    </div>
                  )}
                </div>
                <div className={style.flexSection} style={{ marginBottom: 0 }}>
                  {posts.length > 3 && (
                    <div className={style.topPost}>
                      <PostTile post={posts.at(3)} />
                    </div>
                  )}
                  {posts.length > 4 && (
                    <div className={style.topPost}>
                      <PostTile post={posts.at(4)} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* {posts.length > 5 && (
              <div className={style.flexSection}>
                <PostTile post={posts[5]} className={style.bottomPost} />
                <PostTile post={posts[6]} className={style.bottomPost} />
                <PostTile post={posts[7]} className={style.bottomPost} />
                <PostTile post={posts[8]} className={style.bottomPost} />
              </div>
            )} */}
          </div>
        </>
      ) : (
        <Icon.Spinner size={100} />
      )}
    </div>
  );
};

TitleInternal.displayName = "Title";
export const Title = connect((state: RootState) => {
  return {
    posts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
  };
}, {})(TitleInternal);
