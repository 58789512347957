import { media, style as s } from "typestyle";
import * as Style from "../../../styles";
import { postTitle } from "../../../components/PostTile/PostTile.style";

export const component = s({
  marginBottom: 35,
  fontFamily: Style.text.font.main,
  background: "#F8F8F888",
});

export const banner = s({
  display: "flex",
  fontSize: 28,
  fontWeight: 700,
  fontStyle: "italic",
  textTransform: "uppercase",
  marginBottom: 25,
  backgroundColor: Style.colors.second,
  color: "#F4F4F4",
  padding: "10px 17px",
});

export const posts = s({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
});

export const post = s({
  flex: "0 0 calc(25% - 12px)",
  marginRight: 16,
  marginBottom: 12,
  $nest: {
    "&:nth-child(4n)": {
      marginRight: 0,
    },
    [`.${postTitle}`]: {
      fontSize: 24,
    },
  },
});

export const seeMore = s({
  width: "100%",
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  background: Style.colors.third,
  color: Style.text.color.inverse,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      background: Style.colors.second,
      color: Style.text.color.inverseSubtle,
    },
  },
  cursor: "pointer",
  fontSize: 14,
  textTransform: "uppercase",
  letterSpacing: "2px",
});
