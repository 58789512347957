import * as Models from "../../models";
import { Actions, ActionTypes } from "../actions";

export interface BlogStore {
  postsBySlug: { [slug: string]: Models.Post };
}

export const initialState: BlogStore = {
  postsBySlug: {},
};

export const blog = (
  state: BlogStore = initialState,
  action: Actions,
): BlogStore => {
  switch (action.type) {
    case ActionTypes.REQUEST_BLOG_POSTS_SUCCESS: {
      const postsBySlug: { [slug: string]: Models.Post } = {
        ...state.postsBySlug,
      };
      action.payload.blogs.forEach((blog) => {
        postsBySlug[blog.slug] = blog;
      });
      return {
        postsBySlug,
      };
    }
    default:
      return state;
  }
};
