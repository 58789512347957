import * as React from "react";
import * as style from "./Scoreboard.style";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import { ActionTypes } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { IKImage } from "imagekitio-react";
import * as Models from "../../models";
import { Banner, Icon, RankingsTable } from "../../components";
import { hooks } from "../../utility";

interface StateProps {
  players: { [year: string]: Models.Player[] };
  schools: { [division: string]: Models.School[] };
  inProgress: boolean;
  posts: Models.Post[];
}

const ScoreboardInternal: React.FC<StateProps> = ({
  players,
  schools,
  inProgress,
  posts,
}) => {
  const dispatch = useDispatch();
  const display = hooks.useDisplayContext();
  const [selectedHeaderTab, setSelectedHeaderTab] = React.useState<
    "Public" | "Private"
  >("Public");
  React.useEffect(() => {
    dispatch({ type: ActionTypes.GET_RANKINGS });
    if ((window as any).twttr) {
      (window as any).twttr.widgets.load();
    }
  }, []);
  return (
    <>
      <div className={style.component}>
        <IKImage
          alt="Maryland High School Baseball Rankings"
          path="/maryland-high-school-baseball-rankings-2.png"
          className={style.bgImage}
        />
        <div className={style.content}>
          <div className={style.flexSection}>
            <div className={style.mainSection}>
              <div className={style.header}>
                <div
                  className={
                    selectedHeaderTab === "Public"
                      ? style.selectedHeaderTab
                      : style.headerTab
                  }
                  onClick={() => setSelectedHeaderTab("Public")}
                >
                  Public Standings
                </div>
                <div
                  className={
                    selectedHeaderTab === "Private"
                      ? style.selectedHeaderTab
                      : style.headerTab
                  }
                  onClick={() => setSelectedHeaderTab("Private")}
                >
                  Private Standings
                </div>
              </div>
              {inProgress ? (
                <Icon.Spinner size={40} />
              ) : selectedHeaderTab === "Public" ? (
                <ul>
                  {Object.keys(scoreboards["Public"]).map((sb) => (
                    <li className={style.scoreboard}>
                      <a
                        target="_blank"
                        className={style.scoreboard}
                        href={scoreboards["Public"][sb]}
                      >
                        {sb}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul>
                  {Object.keys(scoreboards["Private"]).map((sb) => (
                    <li className={style.scoreboard}>
                      <a
                        target="_blank"
                        className={style.scoreboard}
                        href={scoreboards["Private"][sb]}
                      >
                        {sb}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              <Banner className={style.banner} title="Recent Articles" />
              <div className={style.articles}>
                {posts.slice(0, 20).map((post) => (
                  <Link
                    to={`/posts/${post.slug}`}
                    className={style.link}
                    key={post.slug}
                  >
                    <div className={style.story}>
                      {post.title.rendered}
                      <span> Kyle Campbell</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            {!display.isMobile && (
              <div className={style.sidebar}>
                <Banner title="Recent Articles" />
                {!!posts &&
                  posts.slice(0, 10).map((post) => (
                    <Link
                      to={`/posts/${post.slug}`}
                      key={post.slug}
                      className={style.sidebarLink}
                    >
                      <div className={style.sidebarItem}>
                        {post.title.rendered}
                      </div>
                    </Link>
                  ))}
                <Banner title="Latest Tweets" className={style.sidebarHeader} />
                <a
                  className="twitter-timeline"
                  data-height="650"
                  data-chrome="noscrollbar noheader nofooter"
                  href="https://twitter.com/xposure_sports?ref_src=twsrc%5Etfw"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ScoreboardInternal.displayName = "Scoreboard";
export const Scoreboard = connect((state: RootState) => {
  return {
    players: state.rankings.players,
    schools: state.rankings.schools,
    inProgress: state.rankings.inProgress,
    posts: Object.values(state.blog.postsBySlug).filter(
      (post) => post.slug !== "about-xposure-sports",
    ),
  };
}, {})(ScoreboardInternal);

const scoreboards: Record<string, Record<string, string>> = {
  ["Public"]: {
    ["Master"]: "https://xposuresports.com/posts/public-master",
    "Anne Arundel County":
      "https://xposuresports.com/posts/anne-arundel-county-schedule-scores",
    "baltimore city":
      "https://xposuresports.com/posts/baltimore-city-schedule-and-scores",
    "baltimore county":
      "https://xposuresports.com/posts/baltimore-county-schedule-and-scores",
    "Bayside Conference": "https://xposuresports.com/posts/bayside-schedule",
    "Carroll County":
      "https://xposuresports.com/posts/carroll-county-schedule-and-scores",
    "Central Maryland Conference":
      "https://xposuresports.com/posts/cmc-schedule-and-scores",
    "Howard County":
      "https://xposuresports.com/posts/howard-county-schedule-and-scores",
    "Montgomery County":
      "https://xposuresports.com/posts/montgomery-county-schedule-and-scores",
    "Prince George's County":
      "https://xposuresports.com/posts/prince-georges-county-schedule-and-scores",
    "Southern Maryland Athletic Conference":
      "https://xposuresports.com/posts/schedules",
    "Upper Chesapeake Bay Athletic Conference":
      "https://xposuresports.com/posts/ucbac-schedule-and-scores",
    "Western Maryland Athletic Conference":
      "https://xposuresports.com/posts/westmac-schedule-standings",
  },
  ["Private"]: {
    ["MIAA"]: "https://xposuresports.com/posts/2024-miaa-schedule-and-scores",
    "Other MD Privates": "https://xposuresports.com/posts/other-md-privates",
  },
};
