import React from "react";
import { colors } from "../../styles";

interface ComponentProps {
  className?: string;
  fill?: string;
  stroke?: string;
  height?: number;
  width?: number;
  size?: number;
  onClick?: () => void;
}

export const Icon: (Icon: any) => React.FC<ComponentProps> =
  (Icon: any) =>
  ({ fill, stroke, height, width, size, onClick, className }) => {
    return (
      <div className={className}>
        <Icon
          height={height || size}
          width={width || size}
          fill={fill || colors.darkTranslucent750}
          stroke={stroke || colors.darkTranslucent750}
          onClick={onClick}
        />
      </div>
    );
  };
