import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  textDecoration: "none",
  cursor: "pointer",
  position: "relative",
  fontSize: Style.text.fontSize.smallMed,
  fontFamily: Style.text.font.main,
  color: Style.text.color.main,
  $nest: {
    "&:hover": {
      color: Style.colors.third,
    },
  },
});

export const title = s(
  {
    fontWeight: 500,
    textTransform: "uppercase",
    height: 50,
    fontSize: 18,
    transition: ".2s ease-in",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      alignItems: "center",
    },
  ),
);

export const dropDown = s({
  position: "absolute",
  top: 50,
  left: `-25%`,
  width: "150%",
  borderTop: `2px solid ${Style.colors.primary}`,
  backgroundColor: Style.colors.translucent950,
  padding: "15px 0px",
});

export const dropDownItem = s({
  color: Style.colors.second,
  padding: `5px 10px`,
  letterSpacing: "1px",
  textDecoration: "none",
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      color: Style.colors.third,
      backgroundColor: Style.colors.darkTranslucent200,
    },
  },
});

export const mobileDropDown = s({
  padding: "10px 20px",
  backgroundColor: Style.colors.translucent150,
  marginTop: 10,
});

export const mobileItem = s({
  color: Style.text.color.inverse,
  letterSpacing: "1px",
  textDecoration: "none",
  transition: ".2s ease-in",
  fontWeight: 500,
  fontSize: Style.text.fontSize.smallMed,
  $nest: {
    div: {
      marginBottom: 15,
    },
  },
});
