import * as React from "react";
import { classes } from "typestyle";
import * as style from "./Table.style";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}
/**
 * Table component
 */
const Table: React.FC<ComponentProps & { children?: React.ReactNode }> = ({
  className,
  children,
}) => {
  return <div className={classes(className, style.component)}>{children}</div>;
};

Table.displayName = "Table";

export { Table };
