import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  fontFamily: Style.text.font.main,
});

export const featuredImage = s(
  {
    width: "100%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      aspectRatio: "16 / 9",
    },
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: "80%",
    },
  ),
);

export const shareContainer = s({
  paddingLeft: 10,
  marginBottom: 15,
  display: "flex",
  alignItems: "center",
});

export const icon = s({
  width: 25,
  marginRight: 5,
  cursor: "pointer",
  $nest: {
    svg: {
      transition: ".2s ease-in",
      stroke: Style.colors.second,
      fill: Style.colors.second,
      $nest: {
        "&:hover": {
          stroke: `${Style.colors.second}70`,
          fill: `${Style.colors.second}70`,
        },
      },
    },
  },
});

export const share = s({
  textTransform: "uppercase",
  color: "#7E7E7E",
  fontSize: 18,
  marginRight: 20,
});

export const title = s({
  fontSize: 37,
  fontWeight: 700,
  marginBottom: 10,
});

export const authorDate = s({
  color: "#898989",
  fontSize: 10,
});

export const authorDateDot = s({
  marginBottom: 20,
  padding: `0px 5px`,
});

export const content = s({
  padding: 10,
});

export const innerHtml = s(
  {
    borderBottom: "2px solid #E1E1E1",
    fontSize: 17,
    fontWeight: 400,
    fontFamily: Style.text.font.third,
    color: "#4C4C4C",
    lineHeight: "26px",
    marginBottom: 20,
    $nest: {
      img: {
        maxWidth: "100%",
      },
      table: {
        borderCollapse: "collapse",
        border: `1px solid ${Style.colors.darkTranslucent650}`,
        background: "#FAFAFA",
        marginBottom: 5,
      },
      figure: {
        margin: `0px 0px 5px`,
      },
      td: {
        border: `1px solid ${Style.colors.darkTranslucent650}`,
        fontSize: Style.text.fontSize.smallMed,
        textAlign: "center",
        padding: 10,
        $nest: {
          "&:first-child": {
            color: Style.colors.third,
            width: 40,
          },
        },
      },
      tr: {
        $nest: {
          "&:nth-child(even)": {
            backgroundColor: "#F0F0F0",
          },
        },
      },
      th: {
        textTransform: "uppercase",
        fontWeight: 700,
        padding: "20px 10px",
        fontSize: Style.text.fontSize.small,
        color: Style.colors.black1,
        $nest: {
          "&:first-child": {
            $nest: {
              td: {
                $nest: {
                  "&:first-child": {
                    color: Style.colors.black1,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  media(
    { maxWidth: Style.screensize.xsMax },
    {
      $nest: {
        [`.wp-block-embed`]: {
          maxWidth: "300px !important",
        },
        td: {
          fontSize: Style.text.fontSize.xs,
          textAlign: "center",
          padding: "10px 5px",
          $nest: {
            "&:first-child": {
              color: Style.colors.third,
              width: 30,
            },
          },
        },
        th: {
          textTransform: "uppercase",
          fontWeight: 700,
          padding: "10px 5px",
          fontSize: Style.text.fontSize.xs,
          lineHeight: "14px",
        },
      },
    },
  ),
);
