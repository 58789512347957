import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 22,
    fontWeight: 700,
    fontStyle: "italic",
    textTransform: "uppercase",
    backgroundColor: Style.colors.second,
    color: "#F4F4F4",
    padding: "10px 17px",
    textDecoration: "underline",
    textDecorationColor: Style.colors.primary,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 28,
    },
  ),
);
