import * as React from "react";
import { Link } from "react-router-dom";
import * as style from "./TournamentDetails";
import { Rules } from "../Rules";

interface ComponentProps {
  className?: string;
}

const CharmCityClassic1718: React.FC<ComponentProps> = ({ className }) => {
  return (
    <>
      <div className={style.event}>
        <div>
          <div className={style.eventHeader}>
            <div className={style.eventName}>17U/18U Charm City Classic</div>
            <div className={style.location}>September 9th-11th</div>
            <div className={style.cost}>
              The inaugural 17U/18U Charm City Classic will be held at various
              quality high school and college fields throughout the greater
              Baltimore area.{" "}
            </div>
            <div className={style.divisions}>Divisions</div>
            <div className={style.divisions}>
              <b>17U:</b> 2024 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2005
            </div>
            <div className={style.divisions}>
              <b>18U:</b> 2023 GRADUATE OR YOUNGER OR BORN ON OR AFTER MAY 01,
              2004
            </div>
            <div>
              <Link to={`/tournaments/register`} className={style.link}>
                <div className={style.register}>Request Invite</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.mainInfo}>
        The 17U/18U Charm City Classic is a 16 team tournament with one 16 team
        division, 17U/18U.This tournament is expected to sell out quickly!
      </div>
      <Rules formatImgUrl="/bracket-1.png" />
    </>
  );
};

CharmCityClassic1718.displayName = "CharmCityClassic1718";
export { CharmCityClassic1718 };
