import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    fontFamily: Style.text.font.main,
    boxSizing: "border-box",
    position: "relative",
    padding: `70px 10px`,
    marginBottom: 100,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `70px 40px`,
    },
  ),
);

export const title = s({
  fontSize: 42,
  fontWeight: 600,
  marginBottom: 20,
  textAlign: "center",
  textTransform: "uppercase",
});

export const mainInfo = s(
  {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 400,
    marginBottom: 20,
    textAlign: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 24,
      lineHeight: "28px",
    },
  ),
);

export const details = s({
  fontSize: 20,
  marginBottom: 15,
  fontWeight: 500,
});

export const formatImgContainer = s({
  width: "60%",
  margin: "auto",
  marginBottom: 20,
});

export const formatImg = s({
  width: "100%",
});

export const table = s({
  margin: "auto",
  listStyleType: "decimal",
  borderCollapse: "collapse",
  $nest: {
    table: {
      border: "solid 1px black",
      borderCollapse: "collapse",
      tableLayout: "fixed",
      width: "100%",
    },
    td: {
      border: "solid 1px black",
      borderCollapse: "collapse",
      padding: 5,
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordWrap: "break-word",
      $nest: {
        div: {
          marginBottom: 10,
        },
      },
    },
    th: {
      border: "solid 1px black",
      borderCollapse: "collapse",
      padding: 5,
      textAlign: "center",
      wordWrap: "break-word",
    },
    li: {
      marginBottom: 10,
      fontSize: 16,
      lineHeight: `18px`,
      $nest: {
        ol: {
          paddingTop: 10,
          listStyleType: "lower-alpha",
          $nest: {
            li: {
              $nest: {
                ol: {
                  paddingTop: 10,
                  listStyleType: "lower-roman",
                },
              },
            },
          },
        },
      },
    },
  },
});

export const link = s({
  textDecoration: "none",
});

export const header = s({
  fontSize: 28,
  fontWeight: 600,
  marginBottom: 25,
});

export const list = s({
  fontSize: 18,
  lineHeight: "20px",
  $nest: {
    li: {
      marginBottom: 10,
    },
  },
});
