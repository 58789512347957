import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const modal = s(
  {
    background:
      "radial-gradient(41.63% 41.63% at 50% 50%, rgba(0, 0, 0, 0.7387) 0%, rgba(0, 11, 32, 0.843646) 0.01%, rgba(0, 7, 20, 0.89) 60.01%)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      opacity: 1,
      margin: "auto",
      maxWidth: "fit-content",
      borderRadius: 4,
      left: "auto",
      right: "auto",
    },
  ),
);

export const form = s(
  {
    borderRadius: 15,
    height: "100%",
    maxHeight: "90vh",
    padding: `65px 25px 85px !important`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `25px 85px !important`,
      height: "auto",
      maxHeight: "auto",
    },
  ),
);
